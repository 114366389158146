import { EThemeType } from "redux/reducers";

interface Iprop {
  theme?: EThemeType | undefined
}
export const InfoIcon = ({theme}:Iprop) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
            <path d="M7.25 4.5C7.25 3.63805 6.90759 2.8114 6.2981 2.2019C5.6886 1.59241 4.86195 1.25 4 1.25C3.13805 1.25 2.3114 1.59241 1.7019 2.2019C1.09241 2.8114 0.75 3.63805 0.75 4.5C0.75 5.36195 1.09241 6.1886 1.7019 6.7981C2.3114 7.40759 3.13805 7.75 4 7.75C4.86195 7.75 5.6886 7.40759 6.2981 6.7981C6.90759 6.1886 7.25 5.36195 7.25 4.5ZM0 4.5C0 3.43913 0.421427 2.42172 1.17157 1.67157C1.92172 0.921427 2.93913 0.5 4 0.5C5.06087 0.5 6.07828 0.921427 6.82843 1.67157C7.57857 2.42172 8 3.43913 8 4.5C8 5.56087 7.57857 6.57828 6.82843 7.32843C6.07828 8.07857 5.06087 8.5 4 8.5C2.93913 8.5 1.92172 8.07857 1.17157 7.32843C0.421427 6.57828 0 5.56087 0 4.5ZM2.65313 3.08281C2.77656 2.73438 3.10781 2.5 3.47813 2.5H4.38906C4.93437 2.5 5.375 2.94219 5.375 3.48594C5.375 3.83906 5.18594 4.16562 4.87969 4.34219L4.375 4.63125C4.37187 4.83437 4.20469 5 4 5C3.79219 5 3.625 4.83281 3.625 4.625V4.41406C3.625 4.27969 3.69688 4.15625 3.81406 4.08906L4.50625 3.69219C4.57969 3.65 4.625 3.57187 4.625 3.4875C4.625 3.35625 4.51875 3.25156 4.38906 3.25156H3.47813C3.425 3.25156 3.37813 3.28437 3.36094 3.33437L3.35469 3.35313C3.28594 3.54844 3.07031 3.65 2.87656 3.58125C2.68281 3.5125 2.57969 3.29688 2.64844 3.10313L2.65469 3.08437L2.65313 3.08281ZM3.5 6C3.5 5.86739 3.55268 5.74021 3.64645 5.64645C3.74021 5.55268 3.86739 5.5 4 5.5C4.13261 5.5 4.25979 5.55268 4.35355 5.64645C4.44732 5.74021 4.5 5.86739 4.5 6C4.5 6.13261 4.44732 6.25979 4.35355 6.35355C4.25979 6.44732 4.13261 6.5 4 6.5C3.86739 6.5 3.74021 6.44732 3.64645 6.35355C3.55268 6.25979 3.5 6.13261 3.5 6Z" fill={theme==="light"? "#525252": "rgba(255, 255, 255, 0.8)"} />
        </svg>
    )
}
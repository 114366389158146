import { components, MenuProps } from "react-select";
import { EThemeType } from "redux/reducers";
import styled from "styled-components";

import { IDataItem } from "./Option";

export interface IMenuProps extends MenuProps<IDataItem, false> {
  variant?: EThemeType;
  account?: boolean;
  inviteModel?: boolean;
  workspace?:boolean;
}

const setBackgroundColor = (props: IMenuProps) => {
  if (props.variant === "dark") return "#101113";
  if (props.variant === "light") return "#FFFFFF";
  return "#1B1C1E";
};

const setBackdrop = (props: IMenuProps) => {
  if (props.variant) return "none";
  else return "blur(25px)";
};

const Menu = styled(components.Menu)<IMenuProps>`
  &.react-select__menu {
    border-radius: ${(props) => (props.variant ? "4px" : "20px")};
    backdrop-filter: ${(props) => setBackdrop(props)};
    background-color: ${(props) => setBackgroundColor(props)};
    box-shadow: ${(props) => (props.workspace ? "  0px 5px 15px rgba(0, 0, 0, 0.08), 0px -5px 15px rgba(0, 0, 0, 0.08), 5px 0px 15px rgba(0, 0, 0, 0.08), -5px 0px 15px rgba(0, 0, 0, 0.08)": "0 8px 10px 0 rgba(11, 10, 26, 0.04), 0 3px 14px 0 rgba(11, 10, 26, 0.08), 0 5px 5px 0 rgba(11, 10, 26, 0.12)" )};
    border: ${(props) => (props.workspace ? "0.5px solid #2563EB" : "")}

    & ::-webkit-scrollbar {
      width: 8px;
    }

    & ::-webkit-scrollbar-track {
      background-color: ${(props) => setBackgroundColor(props)};
    }

    & ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 4px;
      width: 2px;
    }

    & .react-select__option {
      padding: 0;
      margin: 0;

      &.react-select__option--is-selected,
      &.react-select__option--is-focused {
        background: rgba(255, 255, 255, 0);
      }
    }
  }
`;

export default Menu;
interface IProp {
  EWorkSpace?: boolean;
}

export const RemoveIcon = ({EWorkSpace}: IProp) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70615 17.2562L4.74365 16.2937L10.0374 11L4.74365 5.70624L5.70615 4.74374L10.9999 10.0375L16.2937 4.74374L17.2562 5.70624L11.9624 11L17.2562 16.2937L16.2937 17.2562L10.9999 11.9625L5.70615 17.2562Z"
        fill={EWorkSpace? "#94A3B8" : "white"}
      />
    </svg>
  );
};

export const CancelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M5 5L14 14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 14L14 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

import { EThemeType } from "redux/reducers";

interface IProp {
    URL?: boolean;
    EditWorkSpace?: boolean;
    theme?: EThemeType | undefined
}

export const EditIcon = ({EditWorkSpace,theme}: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
            <path d="M12.8953 1.17812C12.2965 0.493747 11.3285 0.493747 10.7297 1.17812L9.90664 2.11562L12.5836 5.175L13.4066 4.23437C14.0055 3.55 14.0055 2.44375 13.4066 1.75937L12.8953 1.17812ZM4.71406 8.05312C4.54727 8.24375 4.41875 8.47812 4.34492 8.7375L3.53555 11.5125C3.45625 11.7812 3.51914 12.0781 3.69414 12.2812C3.86914 12.4844 4.12891 12.5531 4.3668 12.4625L6.79492 11.5375C7.01914 11.4531 7.22422 11.3062 7.39375 11.1156L11.9684 5.88437L9.28867 2.82187L4.71406 8.05312ZM2.625 2.5C1.17578 2.5 0 3.84375 0 5.5V13.5C0 15.1562 1.17578 16.5 2.625 16.5H9.625C11.0742 16.5 12.25 15.1562 12.25 13.5V10.5C12.25 9.94687 11.859 9.5 11.375 9.5C10.891 9.5 10.5 9.94687 10.5 10.5V13.5C10.5 14.0531 10.109 14.5 9.625 14.5H2.625C2.14102 14.5 1.75 14.0531 1.75 13.5V5.5C1.75 4.94687 2.14102 4.5 2.625 4.5H5.25C5.73398 4.5 6.125 4.05312 6.125 3.5C6.125 2.94687 5.73398 2.5 5.25 2.5H2.625Z" fill={EditWorkSpace? "#94A3B8" : theme==="light"? "#4338CA": "#527AE6"} />
        </svg>
    )
}

export const PlusIcon = ({ URL,theme }: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
            <path d="M8 1C8 0.446875 7.55312 0 7 0C6.44688 0 6 0.446875 6 1V5.5H1.5C0.946875 5.5 0.5 5.94688 0.5 6.5C0.5 7.05312 0.946875 7.5 1.5 7.5H6V12C6 12.5531 6.44688 13 7 13C7.55312 13 8 12.5531 8 12V7.5H12.5C13.0531 7.5 13.5 7.05312 13.5 6.5C13.5 5.94688 13.0531 5.5 12.5 5.5H8V1Z" fill={URL ? "#fff" : theme==="light"? "#4338CA": "#527AE6"} />
        </svg>
    )
}

export const TrashIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
            <g clip-path="url(#clip0_11595_221)">
                <path d="M4.225 0.553125L4 1H1C0.446875 1 0 1.44687 0 2C0 2.55312 0.446875 3 1 3H13C13.5531 3 14 2.55312 14 2C14 1.44687 13.5531 1 13 1H10L9.775 0.553125C9.60625 0.2125 9.25938 0 8.88125 0H5.11875C4.74062 0 4.39375 0.2125 4.225 0.553125ZM13 4H1L1.6625 14.5938C1.7125 15.3844 2.36875 16 3.15937 16H10.8406C11.6312 16 12.2875 15.3844 12.3375 14.5938L13 4Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_221">
                    <rect width="14" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const RotateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_11595_651)">
                <path d="M4.46562 4.46564C3.91875 5.01251 3.525 5.65313 3.28437 6.33438C3.1 6.85626 2.52813 7.12813 2.00938 6.94376C1.49063 6.75939 1.21562 6.18751 1.4 5.66876C1.7375 4.70938 2.2875 3.81251 3.05 3.05001C5.775 0.32501 10.1844 0.315635 12.9187 3.01876L14.2188 1.71876C14.4344 1.50314 14.7563 1.44064 15.0375 1.55626C15.3188 1.67189 15.5 1.94689 15.5 2.25001V6.25001C15.5 6.66564 15.1656 7.00001 14.75 7.00001H14.4875H10.75C10.4469 7.00001 10.1719 6.81876 10.0562 6.53751C9.94062 6.25626 10.0031 5.93438 10.2188 5.71876L11.5031 4.43439C9.54688 2.51251 6.40625 2.52189 4.4625 4.46564H4.46562ZM0.5 9.75001C0.5 9.33439 0.834375 9.00001 1.25 9.00001H1.4875H1.50937H5.25C5.55312 9.00001 5.82812 9.18126 5.94375 9.46251C6.05937 9.74376 5.99687 10.0656 5.78125 10.2813L4.49687 11.5656C6.45312 13.4875 9.59375 13.4781 11.5375 11.5344C12.0844 10.9875 12.4781 10.3469 12.7188 9.66564C12.9031 9.14376 13.475 8.87189 13.9937 9.05626C14.5125 9.24063 14.7875 9.81251 14.6031 10.3313C14.2656 11.2875 13.7156 12.1844 12.95 12.95C10.225 15.675 5.81563 15.6844 3.08125 12.9813L1.78125 14.2813C1.56562 14.4969 1.24375 14.5594 0.9625 14.4438C0.68125 14.3281 0.5 14.0531 0.5 13.75V10.0125V9.99064V9.75314V9.75001Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_651">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const RefetchIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12055_1065)">
<path d="M4.46562 4.46564C3.91875 5.01251 3.525 5.65313 3.28437 6.33438C3.1 6.85626 2.52813 7.12813 2.00938 6.94376C1.49063 6.75939 1.21562 6.18751 1.4 5.66876C1.7375 4.70938 2.2875 3.81251 3.05 3.05001C5.775 0.32501 10.1844 0.315635 12.9187 3.01876L14.2188 1.71876C14.4344 1.50314 14.7563 1.44064 15.0375 1.55626C15.3188 1.67189 15.5 1.94689 15.5 2.25001V6.25001C15.5 6.66564 15.1656 7.00001 14.75 7.00001H14.4875H10.75C10.4469 7.00001 10.1719 6.81876 10.0562 6.53751C9.94062 6.25626 10.0031 5.93438 10.2188 5.71876L11.5031 4.43439C9.54688 2.51251 6.40625 2.52189 4.4625 4.46564H4.46562ZM0.5 9.75001C0.5 9.33439 0.834375 9.00001 1.25 9.00001H1.4875H1.50937H5.25C5.55312 9.00001 5.82812 9.18126 5.94375 9.46251C6.05937 9.74376 5.99687 10.0656 5.78125 10.2813L4.49687 11.5656C6.45312 13.4875 9.59375 13.4781 11.5375 11.5344C12.0844 10.9875 12.4781 10.3469 12.7188 9.66564C12.9031 9.14376 13.475 8.87189 13.9937 9.05626C14.5125 9.24063 14.7875 9.81251 14.6031 10.3313C14.2656 11.2875 13.7156 12.1844 12.95 12.95C10.225 15.675 5.81563 15.6844 3.08125 12.9813L1.78125 14.2813C1.56562 14.4969 1.24375 14.5594 0.9625 14.4438C0.68125 14.3281 0.5 14.0531 0.5 13.75V10.0125V9.99064V9.75314V9.75001Z" fill="#94A3B8"/>
</g>
<defs>
<clipPath id="clip0_12055_1065">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
    )
}
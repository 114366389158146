import styles from "../workSpaceConversation.module.scss";
import { EditIcon, PlusIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { ConfigureAIBehaviour } from "pages/Workspaces/components/ConfigureAI/ConfigureAIBehaviour";
import { Modal } from "components";
import { useState } from "react";
import CustomButton from "components/Button";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { AddSource } from "./AddSource";
// import { UploadedFiles } from "pages/Workspaces/components/uploadedFiles/UploadedFiles";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import classNames from "classnames";
import { useWindowSize } from "hooks/useWindowSize";
import { UploadedConversationFiles } from "pages/Workspaces/components/uploadedFiles/UploadedConversationFile";
import { useSelector } from "redux/hooks";

const DurationButton = styled(CustomButton)`
background-color: #4338CA;
color: #fff;
flex-shrink: 0;
font-size: 12px;
line-height: 15px;
font-weight: 600;
border-radius: 4px;
padding: 6px 20px;
   @media (max-width:576px) {
        font-size: 11px;
         padding: 5px 16px;
     } 
`;

export const InfoComponent = () => {
    const infoArray = [
        "workspace.conversation.info1",
        "workspace.conversation.info2",
        "workspace.conversation.info3",
        "workspace.conversation.info4",
        "workspace.conversation.info5"
    ];

    const { wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);
    const { width } = useWindowSize();
    const { sidebarWidth } = useSidebar();
    const [editAIBehaviour, setEditAIBehaviour] = useState<boolean>(false);
    const [addSource, setAddSource] = useState<boolean>(false);
    const { theme } = useSelector((state) => state.authReducer);

    const handleEditAIBehaviour = () => {
        setEditAIBehaviour((prev) => !prev);
    }
    const handleAddSource = () => {
        setAddSource((prev) => !prev);
    }

    return (
        <>
            <div className={classNames(styles.rightSide, {
                [styles.rightSidePosition]: ((sidebarWidth > 287 && width <= 1120) || (sidebarWidth > 497 && width <= 1323) || (sidebarWidth > 480 && width <= 1305)),
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
            })}>
                <div className={classNames(styles.KnowledgeContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}>
                    <div className={styles.knowledgeWrapper}>
                        <div className={classNames(styles.researchContainer,{
                            [styles.Adjustposition]: sidebarWidth > 600,
                            [styles.Adjustment]: sidebarWidth > 413,
                        })}>
                            <div className={classNames(styles.title, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>Knowledge</div>
                            <span className={styles.research}>
                                Research & Analysis
                            </span>
                        </div>
                        <div className={styles.addSourcesContainer} onClick={handleEditAIBehaviour}>
                            <span className={classNames(styles.text, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}><FormattedMessage id="workspace.edit.title" /></span>
                            <span className="flex-shrink-0"><EditIcon theme={theme}/></span>
                        </div>
                    </div>

                    <div className={classNames(styles.optionsContainer, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                    })}>
                        <div><FormattedMessage id="workspace.conversation.infohead" /></div>
                        <div><FormattedMessage id="workspace.conversation.infotitle" /></div>
                        <div className={styles.infoStatements}>
                            {infoArray.map((info, index) => (
                                <div key={index}>
                                    - <FormattedMessage id={info} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.knowledgeWrapper}>
                        <div className={classNames(styles.headContainer,{
                            [styles.Adjustposition]: sidebarWidth > 600,
                            [styles.Adjustment]: sidebarWidth > 413,
                        })}>
                            <span className={classNames(styles.heading, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>
                                <FormattedMessage id="workspace.uploadedfiles.head" />
                            </span>
                            <span className={classNames(styles.countSources, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>({wsUploadedFiles.length} sources attached)</span>
                        </div>
                        <div className={styles.addSourcesContainer} onClick={handleAddSource}>
                            <span className={classNames(styles.text, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}><FormattedMessage id="workspace.addsources.title" /></span>
                            <span className="flex-shrink-0"> <PlusIcon theme={theme} /> </span>
                        </div>
                    </div>
                    {/* <UploadedFiles conversation={true} /> */}
                    <UploadedConversationFiles />
                </div>
            </div>
            {editAIBehaviour && (
                <Modal onClose={() => setEditAIBehaviour(false)} isPadding workSpace>
                    <ConfigureAIBehaviour EditIntructionModal={true} onClose={() => setEditAIBehaviour(false)} />
                    <div className="flex justify-end pt-4">
                        <DurationButton>
                            <span className={styles.btn}>
                                <FormattedMessage id="workspace.context.edit.btn" />
                            </span>
                        </DurationButton>
                    </div>
                </Modal>
            )}

            {addSource && (
                <AddSource setAddSource={setAddSource} />
            )}
        </>
    );
}
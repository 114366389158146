import { EThemeType } from "redux/reducers";

interface Iprop {
  theme?: EThemeType | undefined
}
export const ArrowIcon= ({theme}:Iprop) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
<path d="M10.0881 8L9.43182 7.34375L12.1335 4.65057H0.5V3.71307H12.1335L9.43182 1.01136L10.0881 0.363636L13.9062 4.18182L10.0881 8Z" fill= {theme==="light"? "#4338CA": "#527AE6"}/>
</svg>
    );
}
import { MainView } from "views/layout/MainView";
import classNames from "classnames";
import styles from "./workSpaceConversation.module.scss";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { useSelector } from "redux/hooks";
import { Prompt } from "pages/Workspaces/components/icons/Prompt";
import { AIBehaviour } from "pages/Workspaces/components/icons/AIBehaviour";
import { Microphone } from "pages/Workspaces/components/icons/Microphone";
import { LinkIcon } from "pages/Workspaces/components/icons/LinkIcon";
import { UploadFilesIcon } from "pages/Workspaces/components/icons/UploadFilesIcon";
import { SendIcon } from "pages/Workspaces/components/icons/SendIcon";
import { InfoComponent } from "./InfoComponent";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";
import { useState, useEffect } from "react";
// import { useEffectOnce } from "react-use";
import { setCurrentConversation } from "redux/actions";
// import useRouter from "hooks/useRouter";
import useRouter from "hooks/useRouter";
interface IProps extends TextareaAutosizeProps {}

export const WorkspaceConversation = ({ ...props }: IProps) => {
  const { width } = useWindowSize();
  const { sidebarWidth } = useSidebar();
  const { theme } = useSelector((state) => state.authReducer);
  const { pathname } = useRouter();
  const Id = pathname.split("/")[2];
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const handleMouseEnter = (item: string) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  useEffect(() => {
    if (Id) {
      setCurrentConversation(Number(Id));
    }
  }, [Id]);

  return (
    <>
      <MainView>
        <div
          className={classNames(
            "flex flex-col lg:flex-row mx-auto mt-4 w-full flex-0 px-4 md:pl-8 lg:mt-6 md:pr-8 2xl:pr-14 gap-[20px]",
            {
              [styles.ConversationContainer]:
                (sidebarWidth > 287 && width <= 1150) ||
                (sidebarWidth > 497 && width <= 1323) ||
                (sidebarWidth > 480 && width <= 1305),
            }
          )}
        >
          <div
            className={classNames(styles.textAreaContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.activeborder]: true,
              [styles.textContainer]:
                (sidebarWidth > 287 && width <= 1150) ||
                (sidebarWidth > 497 && width <= 1323) ||
                (sidebarWidth > 480 && width <= 1305),
            })}
          >
            <div className={`flex w-[100%] `}>
              <TextareaAutosize
                className={classNames(styles.textarea, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                minRows={3}
                maxRows={25}
                placeholder=""
                {...props}
              />
            </div>
            <div className="flex w-full justify-between">
              <div className={styles.mainContainer}>
                <div
                  className={classNames(styles.Containerbox, {
                    [styles.showcontent]: hoveredItem === "prompt",
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  onMouseEnter={() => handleMouseEnter("prompt")}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="flex-shrink-0">
                    <Prompt />
                  </span>
                  {hoveredItem === "prompt" && (
                    <span
                      className={classNames(styles.label, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      Prompt Library
                    </span>
                  )}
                </div>
                <div
                  className={classNames(styles.Containerbox, {
                    [styles.showcontentClaude]: hoveredItem === "claude",
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  onMouseEnter={() => handleMouseEnter("claude")}
                  onMouseLeave={handleMouseLeave}
                >
                  <div
                    className={classNames(styles.SubContainerBox, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  />
                  {hoveredItem === "claude" && (
                    <span
                      className={classNames(styles.label, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      Claude 3.5
                    </span>
                  )}
                </div>
                <div
                  className={classNames(styles.Containerbox, {
                    [styles.showcontentAI]: hoveredItem === "aiBehaviour",
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                  onMouseEnter={() => handleMouseEnter("aiBehaviour")}
                  onMouseLeave={handleMouseLeave}
                >
                  <span className="flex-shrink-0">
                    <AIBehaviour />
                  </span>
                  {hoveredItem === "aiBehaviour" && (
                    <span
                      className={classNames(styles.label, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}
                    >
                      Normal
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.mainContainer}>
                <div
                  className={classNames(styles.rightContainerbox, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <UploadFilesIcon />
                </div>
                <div
                  className={classNames(styles.rightContainerbox, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <LinkIcon />
                </div>
                <div
                  className={classNames(styles.rightContainerbox, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                  })}
                >
                  <Microphone />
                </div>
                <div
                  className={`${styles.rightContainerbox} ${styles.sendContainer}`}
                >
                  <SendIcon />
                </div>
              </div>
            </div>
          </div>
          <InfoComponent />
        </div>
      </MainView>
    </>
  );
};

interface IProp {
    richContext?: boolean;
}
export const SuccessfulUploadingIcon = ({richContext}: IProp) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={richContext? "16" : "18"} height="16" viewBox="0 0 18 16" fill="none">
            <g clip-path="url(#clip0_11642_423)">
                <path d="M0 2C0 0.896875 0.896875 0 2 0H7V4C7 4.55312 7.44688 5 8 5H12V6.20625C9.69063 6.85938 8 8.98125 8 11.5C8 13.3469 8.90938 14.9781 10.3031 15.9781C10.2031 15.9937 10.1031 16 10 16H2C0.896875 16 0 15.1031 0 14V2ZM12 4H8V0L12 4ZM9 11.5C9 10.3065 9.47411 9.16193 10.318 8.31802C11.1619 7.47411 12.3065 7 13.5 7C14.6935 7 15.8381 7.47411 16.682 8.31802C17.5259 9.16193 18 10.3065 18 11.5C18 12.6935 17.5259 13.8381 16.682 14.682C15.8381 15.5259 14.6935 16 13.5 16C12.3065 16 11.1619 15.5259 10.318 14.682C9.47411 13.8381 9 12.6935 9 11.5ZM15.6031 10.1469C15.4094 9.95312 15.0906 9.95312 14.8969 10.1469L13 12.0437L12.1031 11.1469C11.9094 10.9531 11.5906 10.9531 11.3969 11.1469C11.2031 11.3406 11.2031 11.6594 11.3969 11.8531L12.6469 13.1031C12.8406 13.2969 13.1594 13.2969 13.3531 13.1031L15.6031 10.8531C15.7969 10.6594 15.7969 10.3406 15.6031 10.1469Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11642_423">
                    <rect width="18" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
import {
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useState,
  useEffect,
} from "react";
import classNames from "classnames";
import { TextareaAutosizeProps } from "react-textarea-autosize";

import { UploadIcon } from "../icons/UploadIcon";
import styles from "./docUpload.module.scss";
import Dropdown from "rc-dropdown";
import Menu from "rc-menu";

import { useSelector } from "redux/hooks";
import { DocUrl } from "../icons/DocUrl";
import { UploadCom } from "../icons/UploadCom";
import { useIntl } from "react-intl";
import { UploadURL } from "./UploadURL";

import { IChat, setChatModel, ChatType, IChatModel } from "redux/actions";
import {
  IErrorMessage,
  IFileErrorState,
} from "pages/ChatPage/pages/newChat/NewChat";
import { ErrorModal } from "pages/ChatPage/pages/newChat/components/ErrorModal";
import useRouter from "hooks/useRouter";

import {
  classifyFiles,
  hasInvalidAudioDuration,
  hasInvalidVideoDuration,
  validateFile,
  validateFileTypes,
  validateImgFile,
  validateMaxFileCount,
} from "utils/fileService";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { convertFileNamesToLowercase, ErrorMessages } from "utils/functions";
import { RoutePaths } from "pages/routePaths";
import { FileSizeLimitModal } from "../fileSizeLimitModal";
import { useFileUploader } from "utils/fileUploadS3";
import { AllowedFileTypes, supportedModels } from "utils/constants";
import { useWindowSize } from "hooks/useWindowSize";
import {
  audioMaxCountReached,
  documentMaxCountReached,
  videoMaxCountReached,
} from "utils/chat";

interface IProps extends TextareaAutosizeProps {
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  selectedFile?: File[] | null;
  setUploadUrl?: Dispatch<SetStateAction<boolean>>;
  resetMessage?: () => void;
  message?: string;
  url?: string;
  isloading?: boolean;
  setURL?: Dispatch<SetStateAction<string>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
}

interface Options {
  Icon: any;
  title: string;
}

export const DocUpload = ({
  setSelectedFile,
  setIsMainScreenOpen,
  selectedFile,
  message,
  resetMessage,
  setUploadUrl,
  url,
  setURL,
  onSendMessage,
  setOpenHistory,
  setUploadingFiles,
  setFileS3Link,
  setIsFileUploading,
  uploadingFiles,
  isAllowUploadFile,
  isloading,
}: IProps) => {
  const { theme, gptModel, userDetail } = useSelector(
    (state) => state.authReducer
  );
  const { newMessages, messages, errorDisplay } = useSelector(
    (state) => state.chatReducer
  );
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const { pathname, push } = useRouter();
  const [uploadURL, setISUploadURL] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [errorModal, setErrorModal] = useState<IFileErrorState>({
    message: "",
    show: false,
  });
  const [changeModel, setChangeModel] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
  const history = pathname.includes("/chat/history");
  const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;
  const { uploadToS3 } = useFileUploader();
  const { width } = useWindowSize();
  useEffect(() => {
    if (errorDisplay) setErrorModal({ message: "", show: false });
  }, [errorDisplay]);

  const docUploadOptions: Options[] = [
    {
      Icon: DocUrl,
      title: formatMessage({ id: "doc.upload.url" }),
    },
    {
      Icon: UploadCom,
      title: formatMessage({ id: "doc.upload.computer" }),
    },
  ];

  // Helper function to set the error modal
  const setValidationError = (messageKey: string) => {
    setErrorModal({
      message: formatMessage({ id: messageKey }),
      show: true,
    });
  };

  // Helper function to reset file upload state
  const resetFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value = "";
    setSelectedFile!(null);
    setUploadingFiles!([]);
    setIsMainScreenOpen!(true);
  };

  const validateAndSetModel = (modelKey: string) => {
    const storedModel = localStorage.getItem(modelKey);
    const selectedModel = storedModel
      ? JSON.parse(storedModel)
      : modelKey === "imageChatGptModel"
        ? chatModels[1]
        : chatModels[0];
    localStorage.setItem(modelKey, JSON.stringify(selectedModel));
    localStorage.setItem("GptModel", JSON.stringify(selectedModel));
    setChatModel(selectedModel);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsMainScreenOpen!(true);
    setOpenHistory!(false);

    if (!gptModel?.type?.includes(ChatType.document)) {
      setChangeModel(true);
      validateAndSetModel("documentChatGptModel");
    }

    const file = event.target.files || null;
    if (file) {
      const newFilesArray = Array.from(file);
      const { imageFiles, videoFiles, audioFiles, otherFiles, docFiles } =
        classifyFiles(newFilesArray);
      const validationError = validateFileTypes(
        newFilesArray,
        setValidationError,
        newMessages,
        messages,
        history,
        uploadingFiles
      );

      if (validationError) {
        event.target.value = "";
        return;
      }

      if (
        videoFiles.length > 1 ||
        (uploadingFiles &&
          uploadingFiles.length > 0 &&
          uploadingFiles[0]?.fileType === "video" &&
          videoFiles.length > 0)
      ) {
        setValidationError("validate.video.upload");
        event.target.value = "";
        return;
      }

      // Validate video files
      if ((await hasInvalidVideoDuration(videoFiles)) && !validationError) {
        setValidationError("validate.video.duration");
        event.target.value = "";
        return;
      }

      // Validate audio files
      if ((await hasInvalidAudioDuration(audioFiles)) && !validationError) {
        setValidationError("validate.audio.duration");
        event.target.value = "";
        return;
      }

      if (
        imageFiles.length === 0 &&
        !validateMaxFileCount(audioFiles, 10, uploadingFiles)
      ) {
        setValidationError("audioChat.file.fileLimit");
        event.target.value = "";
        return;
      }

      if (
        docFiles.length > 0 &&
        !validateMaxFileCount(otherFiles, 5, uploadingFiles)
      ) {
        setValidationError("documentChat.file.fileLimit");
        event.target.value = "";
        return;
      }

      if (imageFiles.length > 0 && !validationError) {
        if (
          changeModel &&
          gptModel?.name &&
          !supportedModels.includes(gptModel?.name)
        ) {
          const imageChatModel = localStorage.getItem("imageChatGptModel");
          if (imageChatModel) {
            localStorage.setItem("GptModel", imageChatModel);
            setChatModel(JSON.parse(imageChatModel));
          }
        }

        // Validate file limit before updating the state
        const total =
          (uploadingFiles ? uploadingFiles.length : 0) + imageFiles.length;
        if (uploadingFiles && total > 20) {
          setValidationError("ImageChat.file.fileLimit");
          event.target.value = "";
          return; // Stop further execution if validation fails
        }
        setSelectedFile!((prevSelectedFiles) => {
          const combinedFiles = prevSelectedFiles
            ? [...prevSelectedFiles, ...imageFiles]
            : imageFiles;
          if (
            file &&
            file[0]?.type.startsWith("image/") &&
            !gptModel?.type.includes("image_chat")
          ) {
            if (gptModel?.name && !supportedModels.includes(gptModel?.name)) {
              validateAndSetModel("imageChatGptModel");
            }
          }
          if (total > 20) {
            const firstThreeFiles = combinedFiles.slice(0, 20);
            setValidationError("ImageChat.file.fileLimit");
            return firstThreeFiles;
          } else return combinedFiles;
        });
        setChangeModel(false);
        const imgFile = convertFileNamesToLowercase(imageFiles);
        event.target.value = "";
        await uploadFilesToS3(imgFile);
      } else if (videoFiles.length > 0 && !validationError) {
        if (changeModel && gptModel?.name) {
          const imageChatModel = localStorage.getItem("videoChatGptModel");
          if (imageChatModel) {
            localStorage.setItem("GptModel", imageChatModel);
            setChatModel(JSON.parse(imageChatModel));
          }
        }

        if (videoMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("videoChat.plan.max_count");
          resetFileUpload(event);
          return;
        }

        const videoSupportedModel = chatModels
          .filter((model) => model.type.includes(ChatType.video))
          .map((model) => model.name);

        setSelectedFile!((prevSelectedFiles) => {
          const combinedFiles = prevSelectedFiles
            ? [...prevSelectedFiles, ...otherFiles]
            : otherFiles;

          if (!videoSupportedModel.includes(gptModel?.name ?? "")) {
            const imageChatModel = localStorage.getItem("videoChatGptModel");
            const model = JSON.parse(imageChatModel ?? "");
            if (!videoSupportedModel.includes(model?.name ?? "")) {
              const selectedModel = chatModels[0];
              localStorage.setItem(
                "videoChatGptModel",
                JSON.stringify(selectedModel)
              );
              localStorage.setItem("GptModel", JSON.stringify(selectedModel));
              setChatModel(selectedModel);
            } else {
              validateAndSetModel("videoChatGptModel");
            }
          }

          return combinedFiles;
        });
        event.target.value = "";
        await uploadFilesToS3(videoFiles);
      } else if (audioFiles.length > 0 && !validationError) {
        if (changeModel && gptModel?.name) {
          const imageChatModel = localStorage.getItem("audioChatGptModel");
          if (imageChatModel) {
            localStorage.setItem("GptModel", imageChatModel);
            setChatModel(JSON.parse(imageChatModel));
          }
        }

        if (audioMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("audioChat.plan.max_count");
          resetFileUpload(event);
          return;
        }

        const audioSupportedModel = chatModels
          .filter((model) => model.type.includes(ChatType.audio))
          .map((model) => model.name);

        setSelectedFile!((prevSelectedFiles) => {
          const combinedFiles = prevSelectedFiles
            ? [...prevSelectedFiles, ...otherFiles]
            : otherFiles;

          if (!audioSupportedModel.includes(gptModel?.name ?? "")) {
            const imageChatModel = localStorage.getItem("audioChatGptModel");
            const model =
              imageChatModel !== "undefined"
                ? JSON.parse(imageChatModel ?? "")
                : null;
            if (!audioSupportedModel.includes(model?.name ?? "")) {
              const selectedModel = chatModels[0];
              localStorage.setItem(
                "audioChatGptModel",
                JSON.stringify(selectedModel)
              );
              localStorage.setItem("GptModel", JSON.stringify(selectedModel));
              setChatModel(selectedModel);
            } else {
              validateAndSetModel("audioChatGptModel");
            }
          }
          return combinedFiles;
        });

        const totalFilesCount =
          (uploadingFiles ? uploadingFiles.length : 0) + audioFiles.length;
        const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
        if (isFreePlan && totalFilesCount > 1) {
          setMessageId!("audioChat.plan.max_count");
          event.target.value = "";
          return;
        } else {
          setSelectedFile!((prevSelectedFiles) => {
            const combinedFiles = prevSelectedFiles
              ? [...prevSelectedFiles, ...otherFiles]
              : otherFiles;
            return combinedFiles;
          });
          event.target.value = "";
          await uploadFilesToS3(audioFiles);
        }
      } else if (otherFiles.length > 0 && !validationError) {
        if (documentMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("documentChat.plan.max_count");
          resetFileUpload(event);
          return;
        }
        const totalFilesCount =
          (uploadingFiles ? uploadingFiles.length : 0) + otherFiles.length;
        const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
        if (isFreePlan && totalFilesCount > 1) {
          setMessageId!("documentChat.plan.max_count");
          event.target.value = "";
          return;
        } else {
          setSelectedFile!((prevSelectedFiles) => {
            const combinedFiles = prevSelectedFiles
              ? [...prevSelectedFiles, ...otherFiles]
              : otherFiles;
            return combinedFiles;
          });
          event.target.value = "";
          await uploadFilesToS3(otherFiles);
        }
      }
    }

    event.target.value = "";
  };

  useEffect(() => {
    if (errorModal.show) {
      // Blur the active element when the error modal is displayed
      document.activeElement instanceof HTMLElement &&
        document.activeElement.blur();
    }
  }, [errorModal.show]);

  const acceptString = AllowedFileTypes.map((val) => ` .${val}`).toString();

  const handleDocUploadURL = () => {
    if (
      uploadingFiles === null ||
      (uploadingFiles && uploadingFiles.length === 0)
    ) {
      setISUploadURL(true);
      setSelectedFile!(null);
      if (
        (newMessages[0]?.images?.length === 0 ||
          messages[0]?.images?.length === 0) &&
        !history
      )
        setIsMainScreenOpen!(true);

      if (!gptModel?.type?.includes(ChatType.document)) {
        const model = localStorage.getItem("documentChatGptModel");
        let selectedModel = model ? JSON.parse(model) : chatModels[0];
        setChangeModel(true);
        if (model) {
          localStorage.setItem("GptModel", model);
        } else {
          localStorage.setItem(
            "documentChatGptModel",
            JSON.stringify(chatModels[0])
          );
          localStorage.setItem("GptModel", JSON.stringify(chatModels[0]));
        }
        setChatModel(selectedModel);
      }
    }
  };

  const onVisibleChange = (visable: boolean) => {
    setVisible(visable);
  };

  useEffect(() => {
    ErrorMessages(errorMessage, setErrorModal);
  }, [errorMessage]);

  useEffect(() => {
    if (visible) {
      setVisible(false);
    }
  }, [selectedFile]);

  const uploadFilesToS3 = async (files: File[]) => {
    if (
      !files[0].type.startsWith("image/") &&
      !files[0]?.type.startsWith("video/") &&
      !files[0]?.type.startsWith("audio/")
    ) {
      if (documentMaxCountReached(currentPlan, userDetail)) {
        setMessageId!("documentChat.plan.max_count");
        setSelectedFile!(null);
        setUploadingFiles!([]);
        setIsMainScreenOpen!(true);
        return;
      }
    }

    const fileArray = Array.from(files);
    const fileType = files[0]?.type.startsWith("image/")
      ? "image"
      : files[0]?.type.startsWith("video/")
        ? "video"
        : files[0]?.type.startsWith("audio/")
          ? "audio"
          : "document";
    const validFiles =
      fileType === "image"
        ? validateImgFile(
            fileArray,
            setErrorMessage,
            claudAllowedFileSize,
            formatMessage
          )
        : validateFile(
            fileArray,
            setErrorModal,
            userDetail,
            setMessageId,
            setIsMainScreenOpen
          );

    if (validFiles) {
      setIsFileUploading!(true);
      await uploadToS3({
        fileArray,
        setUploadingFiles,
        fileType,
        setIsFileUploading,
        setFileS3Link,
      });
    }
  };

  const onConfirm = () => {
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
  };
  const onCancel = () => {
    setMessageId("");
  };

  return (
    <>
      {!isAllowUploadFile ? (
        <div>
          <div className={classNames(styles.uploadIcon)}>
            <UploadIcon />
          </div>
        </div>
      ) : (
        <Dropdown
          trigger={["click"]}
          visible={visible}
          onVisibleChange={onVisibleChange}
          placement="topLeft"
          overlay={
            <Menu
              style={{
                width:
                  width <= 360 ? "200px" : width <= 576 ? "231px" : "250px",
                height: "auto",
                maxHeight: "250px",
                borderRadius: "10px",
                border: "none",
                backgroundColor: theme === "dark" ? "#252526" : "#ffffff",
                backdropFilter: "blur(25px)",
                boxShadow:
                  theme === "dark"
                    ? "none"
                    : " 0px 8px 18px 0px rgba(120, 120, 120, 0.1)",
                overflow: "hidden",
                position: "relative",
                marginLeft: width <= 576 ? "-11px" : "5px",
                marginBottom: width <= 576 ? "32px" : "20px",
              }}
            >
              <div className={styles.DropDownbody}>
                {docUploadOptions.map((option, index) => (
                  <>
                    <label htmlFor={index === 1 ? "file-input" : ""}>
                      <div
                        className={classNames(
                          `flex ${width <= 576 ? "gap-0" : "gap-4"} justify-center items-center hover:rounded-md hover:cursor-pointe hover:bg-[#EDF1FE] hover:dark:bg-[#2A3249] px-[22px] py-4  `,
                          {
                            [styles.disabled]:
                              ((uploadingFiles !== null &&
                                uploadingFiles &&
                                uploadingFiles?.length > 0) ||
                                isloading) &&
                              option.Icon === DocUrl,
                          }
                        )}
                        data-testid="select-upload-option"
                        onClick={
                          option.Icon === DocUrl
                            ? () => handleDocUploadURL()
                            : () => {
                                setISUploadURL(false);
                                setURL!("");
                              }
                        }
                      >
                        <div className={styles.imagedropdownContainer}>
                          <option.Icon adjustHeight={true} />
                        </div>
                        <div className={styles.textContainer}>
                          <div
                            className={classNames(styles.dropdownModalName, {
                              [styles.dark]: theme === "dark",
                              [styles.light]: theme === "light",
                            })}
                          >
                            {option.title}
                          </div>
                        </div>
                      </div>
                    </label>
                    {index !== docUploadOptions.length - 1 && (
                      <div className={styles.svg} />
                    )}
                  </>
                ))}
              </div>
            </Menu>
          }
        >
          <div>
            <div>
              <span className={`${styles.uploadingIcon} "cursor-pointer"`}>
                <UploadIcon />
              </span>
            </div>
          </div>
        </Dropdown>
      )}

      {uploadURL && (
        <UploadURL
          setISUploadURL={setISUploadURL}
          setErrorModal={setErrorModal}
          setUploadUrl={setUploadUrl}
          message={message}
          resetMessage={resetMessage}
          url={url}
          setURL={setURL}
          onSendMessage={onSendMessage}
          setMessageId={setMessageId}
          changeModel={changeModel}
          setChangeModel={setChangeModel}
        />
      )}

      <input
        id="file-input"
        type="file"
        style={{ display: "none" }}
        accept={acceptString}
        multiple={true}
        onChange={handleFileChange}
      />
      {errorModal.show === true && (
        <div className={styles.containerCenter}>
          <div
            className={classNames(styles.popupContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <ErrorModal
              uploadURL={true}
              message={errorModal.message}
              onClose={() => {
                setErrorModal({ message: "", show: false });
              }}
            />
          </div>
        </div>
      )}

      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

import { useCallback, useState, useEffect } from "react";
import { setAccordionState, setNewChatFolderId } from "redux/actions/chatFolder";
import { useSelector } from "redux/hooks";
import styles from "../../ChatSidebar.module.scss";
import classNames from "classnames";

import { DeleteChatConfirm } from "../DeleteChatConfirm";
import { Spinner } from "components";
import { getAllChats, getFolder, IChat } from "redux/actions";
import React from "react";
import { ChatItem } from "../ChatItem";
import { ChatSearchItem } from "../ChatItem/ChatSearchItem";
import { useWindowSize } from "hooks/useWindowSize";

import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { SelectChat } from "../SelectChat/SelectChat";
import { useChatActions } from "hooks/services/ChatServices/useChatAction";
import { ChatFolderHeader } from "./ChatFolderHeader";
import { useDynamicFolderHeights } from "hooks/services/DynamicFolderCalculation";
import useRouter from "hooks/useRouter";
import { FormattedMessage } from "react-intl";

interface IProp {
    onSelectChatItem?: (chatItem: IChat | undefined, folder_id: number) => void;
    searchQuery: string;
    setSelectedItem: (chatItem: IChat | undefined) => void;
    selectedItem?: IChat | undefined;
    setOpenHistory?: React.Dispatch<React.SetStateAction<boolean>>;
    onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
    perPage: number;
}

export const ChatFolders = ({
    onSelectChatItem,
    searchQuery,
    setSelectedItem,
    selectedItem,
    setOpenHistory,
    onStartNewChat,
    perPage,
}: IProp) => {

    const { pathname } = useRouter();
    const chat_folder_Id = pathname.split("/")[4];
    const { width } = useWindowSize();
    const { toggleSidebar } = useToggleSidebar();
    const { Folders, accordionState } = useSelector((state) => state.chatFolderReducer);
    const { PrivateChat } = useSelector((state) => state.chatReducer);
    const [delFolderLoad, setDelFolderLoad] = useState<boolean>(false);
    const [selectedFolderItem, setSelectedFolderItem] = useState<getFolder | undefined>(
        undefined
    );
    const [chatDelConfirm, setChatDelConfirm] = useState<Record<number | string, boolean>>({});
    const [chatLoading, setChatLoading] = useState<Record<number, boolean>>({});
    const [isLoadMore, setIsLoadMore] = useState<Record<number, boolean>>({});

    const { loading, handleSubmit, handleSelectId, handleCancel, selectAllChat, setSelectAllChats, selectedChatIds, setSelectedChatIds, setManuallySelectedChatIds } = useChatActions(setChatDelConfirm, onStartNewChat, selectedItem);
    const { folderHeights, folderContainerRef, folderRefs } = useDynamicFolderHeights(Folders);

    useEffect(() => {

        if (Folders.length === 0) return;
        const newAccordionState = Folders.reduce<Record<number, boolean>>((acc, folder) => {
            acc[folder.id] = accordionState[folder.id] ?? false;
            if (folder.id === Number(chat_folder_Id)) {
                acc[folder.id] = accordionState[folder.id] ?? true;
            }
            return acc;
        }, {});

        const lastFolderId = Folders[Folders.length - 1]?.id;
        if (lastFolderId !== undefined && lastFolderId !== null && (!chat_folder_Id || chat_folder_Id === undefined))
            newAccordionState[lastFolderId] = accordionState[lastFolderId] ?? true;

        setAccordionState(newAccordionState);
    }, []);

    useEffect(() => {
        Object.entries(selectAllChat).forEach(([folderId, isSelected]) => {
            if (isSelected) {
                const folder = Folders.find((f) => f.id === Number(folderId));
                if (folder) {
                    const allChatIds = folder.chats.map((chat) => chat.id);
                    setSelectedChatIds((prevSelectedChatIds) => ({
                        ...prevSelectedChatIds,
                        [folderId]: allChatIds,
                    }));
                }
            }
        });
    }, [Folders, selectAllChat]);

    const getChats = useCallback(
        (folder: getFolder, nextPage?: number) => {
            getAllChats({ search: "", page: nextPage || folder.pagination.page, perPage, folder_id: folder.id })
                .finally(() => {
                    setChatLoading((prev) => ({ ...prev, [folder.id]: false }));
                    setIsLoadMore((prev) => ({ ...prev, [folder.id]: false }));
                });
        },
        [perPage]
    );

    const toggleAccordion = (folder: getFolder) => {
        const updatedState = { ...accordionState, [folder.id]: !accordionState[folder.id] };
        setAccordionState(updatedState);

        if (updatedState[folder.id] && folder.chats.length === 0) {
            setChatLoading((prev) => ({ ...prev, [folder.id]: true }));
            getChats(folder);
        };
    };

    const handleNewChat = (folder_id: number) => {
        setNewChatFolderId(folder_id);
        onStartNewChat?.({ toChat: true });
    }

    const onSelectChat = (chatItem: IChat | undefined, folder_id: number) => {
        setOpenHistory?.(true);
        setSelectedItem(chatItem);
        onSelectChatItem?.(chatItem, folder_id ?? 0);
        if (width && width <= 768) {
            setTimeout(() => toggleSidebar(), 500);
        }
    };

    const handleSelectAllChats = useCallback(
        (isChecked: boolean, folderId: number | string) => {
            const matchedFolder = Folders.find((folder) => folder.id === folderId);
            const allChatIds = matchedFolder?.chats.map((chat) => chat.id) || [];
            setSelectedChatIds((prev) => ({
                ...prev,
                [folderId]: isChecked ? allChatIds : [],
            }));

            setSelectAllChats((prev) => ({ ...prev, [folderId]: isChecked ? true : false }));
        },
        [Folders, setSelectAllChats, setSelectedChatIds]
    );

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>, folder: getFolder) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        const atBottom = scrollHeight - (scrollTop + clientHeight) <= 1;

        if (atBottom && !isLoadMore[folder.id] && folder.pagination.page < folder.pagination.lastPage) {
            setIsLoadMore((prev) => ({ ...prev, [folder.id]: true }));
            getChats(folder, folder.pagination.page + 1);
        }
    };

    return (
        <div ref={folderContainerRef}>
            {Folders.map((folder) => {
                const showDelete = (selectedChatIds && selectedChatIds[folder.id]?.length >= 1);

                return (
                    <>
                        <ChatFolderHeader
                            folder={folder}
                            folderId={folder.id}
                            folderName={folder.name}
                            accordionState={accordionState}
                            toggleAccordion={toggleAccordion}
                            handleNewChat={handleNewChat}
                            selectedFolderItem={selectedFolderItem}
                            setSelectedFolderItem={setSelectedFolderItem}
                            delFolderLoad={delFolderLoad}
                            setDelFolderLoad={setDelFolderLoad}
                            onStartNewChat={onStartNewChat}
                        />
                        {(showDelete && accordionState[folder.id]) && (
                            <SelectChat
                                setChatDelConfirm={setChatDelConfirm}
                                loading={delFolderLoad}
                                handleSelectAllChats={handleSelectAllChats}
                                selectAllChat={selectAllChat}
                                handleCancel={handleCancel}
                                folder_id={folder.id}
                                selectedChatIds={selectedChatIds}
                            />
                        )}
                        <div className={classNames(styles.content, {
                            [styles.isbackdrop]: PrivateChat,
                            [styles.contentScroll]: true,
                        })}
                            ref={folderRefs.current[folder.id]}
                            onScroll={(e) => handleScroll(e, folder)}
                            style={{
                                overflowY: 'auto',
                                maxHeight: accordionState[folder.id] ? folderHeights[folder.id] || "calc(100% - 48px)" : "calc(100% - 48px)", // Dynamically calculated height
                                transition: "height 0.3s ease",
                            }}
                        >
                            {chatLoading[folder.id] ?
                                <div className="flex justify-center items-center py-[4px]">
                                    <Spinner extraSmall isWhite />
                                </div> :
                                folder.chats.length > 0 && accordionState[folder.id] ?
                                    folder.chats.map((chatItem, key) => (
                                        <React.Fragment>
                                            <ChatItem
                                                key={`topic-${key}`}
                                                chatItem={chatItem}
                                                selectedItem={selectedItem}
                                                onSelectChat={onSelectChat}
                                                searchQuery={searchQuery}
                                                handleSelectId={handleSelectId}
                                                selectedChatIds={selectedChatIds}
                                                setSelectedChatIds={setSelectedChatIds}
                                                onStartNewChat={onStartNewChat}
                                                setSelectedItem={setSelectedItem}
                                                folder_id={folder.id}
                                                setManuallySelectedChatIds={setManuallySelectedChatIds}
                                            />
                                            {(searchQuery && searchQuery.length > 0) && (
                                                <ChatSearchItem
                                                    chatItem={chatItem}
                                                    onSelectChat={onSelectChat}
                                                    searchQuery={searchQuery}
                                                />
                                            )}
                                        </React.Fragment>
                                    )) : (
                                        accordionState[folder.id] && (
                                            <div className={styles.emptySearchMessage} >
                                                <FormattedMessage id="sidebar.chat.noChat.message" />
                                            </div>
                                        )
                                    )
                            }
                            {(isLoadMore[folder.id] && accordionState[folder.id]) && (
                                <div className="flex items-center justify-center py-2">
                                    <Spinner extraSmall isWhite />
                                </div>
                            )}
                        </div>

                        {chatDelConfirm[folder.id] && (
                            <DeleteChatConfirm
                                onCancel={() => {
                                    handleCancel(true, folder.id)
                                    setChatDelConfirm((prev) => ({ ...prev, [folder.id]: false }))
                                }}
                                onSubmit={() => handleSubmit({ searchQuery, folder_id: folder.id })}
                                loading={loading}
                            />
                        )}
                    </>
                );

            })}
        </div>
    );
}
import { BotSettings, updateFileProgress } from "redux/actions";

export interface IOption {
  label: any;
  value: any;
}

interface subOption {
  label: string;
  value: string;
}

interface DropdownOption {
  key: string;
  label: string;
  value: string;
  options: subOption[];
}

export const ImageCountOptions: IOption[] = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
];

export const supportedModels = [
  "Claude-3-Haiku",
  "Claude-3-Opus",
  "Claude 3.5 Sonnet",
  "GPT-4-TURBO",
  "GPT-4-Omni",
];

export const getDropdownOptions = (RollBot: BotSettings): DropdownOption[] => [
  {
    key: "output_format",
    label: "Output Format",
    value: "outputFormats",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.outputFormats || []),
    ],
  },
  {
    key: "tone",
    label: "Tone",
    value: "tones",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.tones || []),
    ],
  },
  {
    key: "style_of_writing",
    label: "Writing Style",
    value: "writingStyles",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.writingStyles || []),
    ],
  },
  {
    key: "language",
    label: "Language",
    value: "language",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.languages || []),
    ],
  },
  {
    key: "length",
    label: "Length",
    value: "responseLengths",
    options: [
      { label: "Default", value: "default" },
      ...(RollBot?.responseLengths || []),
    ],
  },
];

export const responseLoadingMessage = [
  "Evaluating possibilities",
  "Thinking",
  "Breaking down the puzzle",
  "Analyzing the statements",
  "Mapping possibilities",
  "Piercing the clues togather",
];

export const imgURL = ["png", "jpg", "jpeg", "webp", "gif"];

export const videoURL = ["mp4", "mpeg", "mpg", "webm", "wmv", "3gpp"];

export const audioURL = ["wav", "mp3", "flac"];

export const AllowedFileTypes = [
  "pdf",
  "txt",
  "ppt",
  "pptx",
  // "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "eml",
  "srt",
  "png",
  "jpg",
  "jpeg",
  "webp",
  "gif",
  "mp4",
  "mpeg",
  "mpg",
  "webm",
  "wmv",
  "3gpp",
  // "3gp"
  "wav",
  "mp3",
  "flac",
];

export const DocAllowedTypes = [
  "pdf",
  "txt",
  "ppt",
  "pptx",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "eml",
  "srt",
];

export const generateUniqueId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};

export const extractFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
};

export const simulateSourceProgress = (
  fileId: string,
  startProgress: number,
  targetProgress: number,
  duration: number
) => {
  const steps = 20;
  const stepDuration = duration / steps;
  const progressIncrement = (targetProgress - startProgress) / steps;
  let currentStep = 0;

  const interval = setInterval(() => {
    currentStep++;
    const currentProgress = startProgress + progressIncrement * currentStep;

    if (currentStep >= steps) {
      clearInterval(interval);
    } else {
      updateFileProgress(fileId, Math.round(currentProgress));
    }
  }, stepDuration);

  return interval;
};

import { FormattedMessage } from "react-intl";
import styles from "../ConfigureAI/configureAIBehaviour.module.scss";
import classNames from "classnames";

export const Notifications = ({ notificationIds, headingId }: { notificationIds: string[]; headingId: string }) => {
    return (
        <div className={styles.tipsContainer}>
            <div className={styles.tipsHeading}>
                <FormattedMessage id={headingId} />
            </div>
            <div className={classNames(styles.tipsList, { [styles.bulletList]: headingId === "workspace.context.notificationhead" })}>
                {notificationIds.map((id, index) => (
                    <div key={index} className={styles.tipItem}>
                        <span className={styles.tipNumber}>{index + 1}.</span>{" "}
                        <FormattedMessage id={id} />
                    </div>
                ))}
            </div>
        </div>
    );
};
import { FormattedMessage } from "react-intl";
import styles from "./uploadedFiles.module.scss";
import { CrossIcon } from "../icons/CrossIcon";
// import { UploadingIcon } from "../icons/UploadingIcon";
import { TrashIcon } from "../icons/RightSectionIcon";
// import { SuccessfulUploadingIcon } from "../icons/SuccessfulUploadingIcon";
import { useSelector } from "redux/hooks";
// import { UploadingFailIcon } from "../icons/UploadingFailIcon";
import { DeleteS3Link, DeleteUploadedFile, removeFile } from "redux/actions";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
// import { useS3FileUpload } from "hooks/services/AmazonServices";
// import { LinkIcon } from "../icons/LinkIcon";
import classNames from "classnames";

interface Iprops {
  hideContainer?: boolean;
  hideSubhead?: boolean;
}
export const UploadedFiles = ({ hideContainer, hideSubhead }: Iprops) => {
  // const { cancelUpload } = useS3FileUpload();
  const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();
  const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";

  const renderFileIcon = (fileType: string, fileName: string) => {
    if (fileType.includes("pdf")) return <PDFIcon />;
    if (fileType.includes("text/plain")) return <TextIcon />;
    if (fileType.includes("presentation")) return <PPTXIcon />;
    if (fileType.includes("ms-powerpoint")) return <PPTIcon />;
    if (fileType.includes("csv")) return <CSVIcon />;
    if (fileType.includes("sheet")) return <XLSXIcon />;
    if (fileType.includes("ms-excel")) return <XLSIcon />;
    if (fileType.includes("application/x-subrip")) return <SRTIcon />;
    if (fileType.includes("message/rfc822")) return <EMLIcon />;

    // Fallback: check file extension if MIME type is empty
    if (fileType === "" && fileName.endsWith(".srt")) return <SRTIcon />;
    return <DocIcon />;
  };

  const handleRemoveWS = (id: string) => {
    removeFile(id);
  };

  const handleDeleteFile = (
    id: string,
    S3Link: string,
    uploaded_id: number | null
  ) => {
    DeleteS3Link(S3Link);
    if (uploaded_id !== null) {
      DeleteUploadedFile(uploaded_id);
    }
    removeFile(id);
  };

  if (workSpaceFiles && workSpaceFiles.length === 0) return;

  const maxSources = isFreePlan ? 1 : 5;
  const usedSources = workSpaceFiles?.length || 0;

  return (
    <div className={classNames(!hideContainer ? styles.container : styles.addSources,{
      [styles.light]: theme === "light",
      [styles.dark]: theme === "dark",
    })}>
      <div className={styles.headContainer}>
        <h5 className={classNames(styles.heading,{
           [styles.light]: theme === "light",
           [styles.dark]: theme === "dark", 
        })}>
          <FormattedMessage id="workspace.uploadedfiles.head" />
        </h5>
        {!hideSubhead && (
          <div className={classNames(styles.subheading,{
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",  
          })}>
            <FormattedMessage
              id="workspace.uploadedfiles.subhead"
              values={{ used: usedSources, max: maxSources }}
            />
          </div>
        )}
      </div>
      {workSpaceFiles &&
        workSpaceFiles.map((file) => {
          return (
            <div className={classNames(styles.filesContainer,{
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",    
            })}>
              <div className={styles.fileWrapper}>
                {renderFileIcon(file.file.type, file.file.name)}
                <div className={styles.fileNameWrapper}>
                  <div className={classNames(styles.fileName,{
                     [styles.light]: theme === "light",
                     [styles.dark]: theme === "dark",           
                  })}>
                    {" "}
                    {file.file.name === "EMpty.txt"
                      ? decodeURIComponent(
                          file?.S3Link?.split("/").pop() || "unknown"
                        )
                      : file.file.name}
                  </div>
                  {file.errorMessage ? (
                    <span className="text-[12px] font-normal leading-[15px] text-[#DC2626]">
                      {planName === "Free" ? (
                        <FormattedMessage
                          id={file.errorMessage}
                          values={{ size: 10 }}
                        />
                      ) : planName === "Standard" ? (
                        <FormattedMessage
                          id={file.errorMessage}
                          values={{ size: 50 }}
                        />
                      ) : (
                        <FormattedMessage id={file.errorMessage} />
                      )}
                    </span>
                  ) : file.status === "uploading" ? (
                    <div className={classNames(styles.uploadingContainer,{
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",            
                    })}>
                      <div className={styles.status}>
                        <FormattedMessage id="workspace.uploadedfiles.uploading" />
                      </div>
                      <div className={styles.percentage}>{file.progress}%</div>
                    </div>
                  ) : (
                    file.fileSize &&
                    file.status === "uploaded" && (
                      <span className="text-[12px] font-normal leading-[15px] text-[#737373] dark: text-[rgba(255,255,255,0.7)">
                        {file.fileSize
                          ? `${file.fileSize.toFixed(2)}  MB`
                          : "2.4 MB · Updated 2 mins ago"}
                      </span>
                    )
                  )}
                </div>
                <div className={styles.icons}>
                  {file.status === "uploaded" ? (
                    <span
                      onClick={() => {
                        if (file.S3Link) {
                          handleDeleteFile(
                            file.id,
                            file.S3Link,
                            file.uploaded_id ?? null
                          );
                        }
                      }}
                    >
                      <TrashIcon />
                    </span>
                  ) : (
                    <span onClick={() => handleRemoveWS(file.id)}>
                      <CrossIcon />
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

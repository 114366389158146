export const AIBehaviour = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
            <g clip-path="url(#clip0_11595_819)">
                <path d="M9.35398 0.964648L7.93972 2.38145L11.1186 5.56035L12.5329 4.14609C13.0889 3.59004 13.0889 2.69121 12.5329 2.13516L11.3649 0.964648C10.8089 0.408594 9.91003 0.408594 9.35398 0.964648ZM7.31257 2.90195L7.07898 2.97305L3.42019 4.06992C2.91491 4.22227 2.51374 4.6082 2.34616 5.11094L0.0965545 11.8191C7.00876e-05 12.1061 0.0711638 12.426 0.281906 12.6418L4.18191 8.74434C4.10573 8.58438 4.06257 8.40664 4.06257 8.21875C4.06257 7.5459 4.60847 7 5.28132 7C5.95417 7 6.50007 7.5459 6.50007 8.21875C6.50007 8.8916 5.95417 9.4375 5.28132 9.4375C5.09343 9.4375 4.9157 9.39434 4.75573 9.31816L0.855734 13.2182C1.07409 13.4289 1.39148 13.5025 1.67839 13.4035L8.38913 11.1539C8.88933 10.9863 9.2778 10.5852 9.43015 10.0799L10.527 6.42109L10.5956 6.1875L7.31257 2.90195Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_819">
                    <rect width="13" height="13" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
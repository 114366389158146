import { ReactNode, DragEvent } from "react";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import styles from "./Modal.module.scss";

import { useSelector } from "redux/hooks";

export interface ModalProps {
  children?: ReactNode;
  title?: string | ReactNode;
  onClose?: () => void;
  size?: "xs"|"sm" | "md" | "lg" | "xl";
  testId?: string;
  isPadding?: boolean;
  chatSetting?: boolean;
  drag?: boolean;
  onDragOver?: (event: DragEvent<HTMLElement> | undefined) => void;
  onDragLeave?: (event: DragEvent<HTMLElement> | undefined) => void;
  onDrop?: (event: DragEvent<HTMLElement> | undefined) => void;
  errorModel?: boolean;
  modalPrompt?: boolean;
  positionTop?: boolean;
  updateEmail?:boolean;
  editConfirmationEmail?: boolean;
  editEmail?: boolean;
  uploadDoc?: boolean;
  deActivateAccount?: boolean;
  deactivateOptions?: boolean;
  deActivateAccountConfirm?: boolean;
  GPTModel?: boolean;
  addPrompt?:boolean;
  deleteConfirmationModal?: boolean;
  workSpace?: boolean;
}

const cardStyleBySize = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
  workSpace: styles.workSpace,
  chat: styles.chatSettingSize,
  url: styles.urlPrompt,
  adminErr: styles.adminErrModel,
  updateEmail: styles.editEmail,
  email: styles.editConfirmationEmail,
  deActivateAccount: styles.deActivateAccount,
  deactivateOptions: styles.deactivateOptions,
  deActivateAccountConfirm: styles.deActivateAccountConfirm,


};

export const Modal = ({
  children,
  size,
  title,
  onClose,
  testId,
  isPadding,
  chatSetting,
  drag,
  onDragOver,
  onDragLeave,
  onDrop,
  errorModel,
  modalPrompt,
  positionTop,
  updateEmail,
  editEmail,
  editConfirmationEmail,
  uploadDoc,
  deActivateAccount,
  deactivateOptions,
  deActivateAccountConfirm,
  GPTModel,
  addPrompt,
  deleteConfirmationModal,
  workSpace,
}: ModalProps) => {
  
  const { theme } = useSelector((state) => state.authReducer);
  const { AdminDelErrorMsg } = useSelector((state) => state.chatReducer);
  const modalSize = workSpace? "workSpace" : deActivateAccountConfirm? "deActivateAccountConfirm" : deactivateOptions? "deactivateOptions" : deActivateAccount? "deActivateAccount" : AdminDelErrorMsg ? "adminErr" : chatSetting ? "chat" : modalPrompt ? "url" : editEmail ? "updateEmail" : editConfirmationEmail ? "email" : size;

  return (
    <Dialog
      open
      onClose={() => onClose?.()}
      data-testid={testId}
      className={styles.modalPageContainer}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className={classNames(styles.backdrop, {
        [styles.errorBackdrop]: errorModel,
        [styles.errorBackdropLight]: errorModel && theme === 'light',
        [styles.uploadDoc]: uploadDoc && theme=== 'light',
        [styles.uploadDocdark]: theme === 'dark' && uploadDoc,
      })} />
      <div className={classNames(styles.modalContainer, {
        [styles.postionTop]: positionTop,
      })}>
        <Dialog.Panel className={styles.modalPanel}>
          <div
            className={classNames(
              styles.card,
              modalSize ? cardStyleBySize[modalSize] : undefined,
              {
                [styles.light]: theme === "light" && (!drag || errorModel),
                [styles.dark]: theme === "dark" && (!drag || errorModel),
                [styles.isErrorModel]: errorModel,
                [styles.ErrorModelLight]: errorModel && theme === 'light',
                [styles.isPadding]: isPadding,
                [styles.isDrag]: drag,
                [styles.updateBorderRadius]: updateEmail || deleteConfirmationModal,
                [styles.updateEmailLight]: theme === "light" && ((updateEmail || deactivateOptions) || GPTModel || addPrompt || deleteConfirmationModal),
              }
            )}
          >
            {title && <div className={styles.cardTitle}>{title}</div>}
            {children}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

import { FormattedMessage } from "react-intl";
import styles from "./RichContext.module.scss";
import { InfoIcon } from "../icons/InfoIcon";
import { UploadingUrl } from "./UploadingUrl";
import { UploadingFiles } from "./UploadingFiles";
import { LinkIcon } from "../icons/LinkIcon";
import { Notifications } from "../Notifications/Notifications";
import { UploadedFiles } from "../uploadedFiles/UploadedFiles";
import { SuccessfulUploadingIcon } from "../icons/SuccessfulUploadingIcon";
import { useSelector } from "redux/hooks";
import classNames from "classnames";

export const RichContext = () => {
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const isFreePlan = userDetail?.user.activeSubscription?.name === 'Free';
    return (
        <div className="flex flex-col gap-[20px]">
            <div className={classNames(styles.container, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
            })}>
                <div className={classNames(styles.heading, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}>
                    {isFreePlan ? (
                        <FormattedMessage id="workspace.context.freehead" />
                    ) : (
                        <FormattedMessage id="workspace.context.head" />
                    )}
                </div>

                <div className={styles.docContainer}>
                    <div className={styles.docHeadContainer}>
                        <div className="flex-shrink-0">  <SuccessfulUploadingIcon richContext={true} /></div>
                        <div className={classNames(styles.title, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}><FormattedMessage id="workspace.context.title1" /></div>
                        <InfoIcon theme={theme}/>
                    </div>
                    <UploadingFiles />
                </div>

                <div className={styles.docContainer}>
                    <div className={styles.docHeadContainer}>
                        <div className="flex-shrink-0"><LinkIcon /></div>
                        <div className={classNames(styles.title, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}><FormattedMessage id="workspace.context.title2" /></div>
                        <InfoIcon theme={theme}/>
                    </div>
                    <UploadingUrl />
                </div>

                <Notifications
                    notificationIds={[
                        "workspace.context.notification1",
                        "workspace.context.notification2",
                        "workspace.context.notification3",
                        "workspace.context.notification4",
                    ]}
                    headingId="workspace.context.notificationhead"
                />
            </div>

            <UploadedFiles />
        </div>
    );
}
export const Microphone = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
            <g clip-path="url(#clip0_11595_833)">
                <path d="M5.375 0C4.0293 0 2.9375 1.0918 2.9375 2.4375V6.5C2.9375 7.8457 4.0293 8.9375 5.375 8.9375C6.7207 8.9375 7.8125 7.8457 7.8125 6.5V2.4375C7.8125 1.0918 6.7207 0 5.375 0ZM2.125 5.48438C2.125 5.14668 1.85332 4.875 1.51562 4.875C1.17793 4.875 0.90625 5.14668 0.90625 5.48438V6.5C0.90625 8.7623 2.58711 10.6311 4.76562 10.9281V11.7812H3.54688C3.20918 11.7812 2.9375 12.0529 2.9375 12.3906C2.9375 12.7283 3.20918 13 3.54688 13H5.375H7.20312C7.54082 13 7.8125 12.7283 7.8125 12.3906C7.8125 12.0529 7.54082 11.7812 7.20312 11.7812H5.98438V10.9281C8.16289 10.6311 9.84375 8.7623 9.84375 6.5V5.48438C9.84375 5.14668 9.57207 4.875 9.23438 4.875C8.89668 4.875 8.625 5.14668 8.625 5.48438V6.5C8.625 8.29512 7.17012 9.75 5.375 9.75C3.57988 9.75 2.125 8.29512 2.125 6.5V5.48438Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_833">
                    <rect width="9.75" height="13" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
};
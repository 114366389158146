export const Prompt = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
            <g clip-path="url(#clip0_11595_814)">
                <path d="M0.8125 2.9375C0.8125 1.5918 1.9043 0.5 3.25 0.5H5.6875V5.34199C5.6875 5.68223 6.08105 5.87266 6.34766 5.65937L7.71875 4.5625L9.08984 5.65937C9.35645 5.87266 9.75 5.68223 9.75 5.34199V0.5H10.5625H11.375C11.8244 0.5 12.1875 0.863086 12.1875 1.3125V9.4375C12.1875 9.88691 11.8244 10.25 11.375 10.25V11.875C11.8244 11.875 12.1875 12.2381 12.1875 12.6875C12.1875 13.1369 11.8244 13.5 11.375 13.5H10.5625H3.25C1.9043 13.5 0.8125 12.4082 0.8125 11.0625V2.9375ZM2.4375 11.0625C2.4375 11.5119 2.80059 11.875 3.25 11.875H9.75V10.25H3.25C2.80059 10.25 2.4375 10.6131 2.4375 11.0625Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_814">
                    <rect width="11.375" height="13" fill="white" transform="translate(0.8125 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useIntl } from "react-intl";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import classNames from "classnames";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";
import { useSelector } from "redux/hooks";
import { SendButton } from "../sendButton";
import { EChatType, IChat, IChatModel } from "redux/actions";
import { DocUpload } from "../DocumentUpload";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { FileUploadPreview } from "./FileUploadPreview";
import {
  classifyFiles,
  hasInvalidAudioDuration,
  validateFile,
  validateFileTypes,
  validateImgFile,
  validateMaxFileCount,
} from "utils/fileService";

import { useFileUploader } from "utils/fileUploadS3";
import { FileSizeLimitModal } from "../fileSizeLimitModal";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages";
import {
  convertFileNamesToLowercase,
  ErrorMessages,
  handleModelSwitch,
  validateVideoDurations,
} from "utils/functions";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { useWindowSize } from "hooks/useWindowSize";
import { CreditDeduction } from "../chatFooter/components/CreditDeduction";
import { ImageGenerationOptions } from "../chatFooter/components/ImageGenerationOption";
import {
  audioMaxCountReached,
  documentMaxCountReached,
  videoMaxCountReached,
} from "utils/chat";

interface IProps extends TextareaAutosizeProps {
  isloading?: boolean;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  isFieldFocused?: boolean;
  onTextareaFocus?: () => void;
  handleSubmit: (event: React.FormEvent) => void;
  updateChatModel?: (modalType: EChatType) => void;
  selectedFile?: File[] | null;
  setSelectedFile?: Dispatch<SetStateAction<File[] | null>>;
  setIsMainScreenOpen?: Dispatch<SetStateAction<boolean>>;
  setUploadUrl?: Dispatch<SetStateAction<boolean>>;
  resetMessage?: () => void;
  message?: string;
  url?: string;
  setURL?: Dispatch<SetStateAction<string>>;
  onSendMessage?: (
    question: string,
    chatModels?: IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => void;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading?: Dispatch<SetStateAction<boolean>>;
  isAllowUploadFile?: boolean;
  chatItem?: IChat;
  setErrorModal: Dispatch<SetStateAction<IFileErrorState>>;
  getMessagesLoading: boolean;
  credit: number;
  fileS3Link?: string[];
}

const Spinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 18px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: #527ae6;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

export const SearchField = memo(
  ({
    isloading,
    textareaRef,
    isFieldFocused,
    onTextareaFocus,
    handleSubmit,
    updateChatModel,
    selectedFile,
    setSelectedFile,
    setIsMainScreenOpen,
    setUploadUrl,
    resetMessage,
    message,
    url,
    setURL,
    onSendMessage,
    setOpenHistory,
    uploadingFiles,
    setUploadingFiles,
    setFileS3Link,
    setIsFileUploading,
    isAllowUploadFile,
    chatItem,
    setErrorModal,
    getMessagesLoading,
    credit,
    fileS3Link,
    ...props
  }: IProps) => {
    const { theme, userDetail, gptModel } = useSelector(
      (state) => state.authReducer
    );
    const currentPlan = useSelector(
      (state) => state.planSubscriptionReducer.activePlan
    );
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);
    const { formatMessage } = useIntl();
    const { uploadToS3 } = useFileUploader();
    const { push, pathname } = useRouter();
    const history = pathname.includes("/chat/history");
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [messageId, setMessageId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<IErrorMessage[]>([]);
    const { isOpen } = useToggleSidebar();
    const { width } = useWindowSize();

    useEffect(() => {
      ErrorMessages(errorMessage, setErrorModal);
    }, [errorMessage]);

    const claudAllowedFileSize = gptModel?.name.includes("GPT-4") ? 15 : 5;

    const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (textareaRef.current) textareaRef.current.focus();
    };

    const handlePaste = async (
      event: React.ClipboardEvent<HTMLTextAreaElement>
    ) => {
      setOpenHistory!(false);
      const items = event.clipboardData.items;
      const files: File[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file) {
            if (
              (history && chatItem && chatItem.chat_type === "text") ||
              newMessages[0]?.chatType === "text" ||
              messages[0]?.chatType === "text"
            ) {
            } else if (
              newMessages.length === 0 ||
              (newMessages[0]?.files && newMessages[0]?.files.length > 0) ||
              (newMessages[0]?.images && newMessages[0]?.images.length > 0) ||
              (chatItem && chatItem.chat_type === "image_chat")
            )
              files.push(file);
          }
        }
      }

      if (files.length > 0) {
        const isImageFile = files[0].type.startsWith("image/");
        const imgFiles = isImageFile
          ? convertFileNamesToLowercase(files)
          : files;
        await uploadFilesToS3(imgFiles);
      }
    };

    // Helper function to set the error modal
    const setValidationError = (messageKey: string) => {
      setErrorModal({
        message: formatMessage({ id: messageKey }),
        show: true,
      });
    };

    {
      /* /////////////////Validate File///////////////*/
    }

    const validateFileSelection = useCallback(
      async (files: File[]) => {
        const isImageFile = files[0].type.startsWith("image/");

        const { imageFiles, docFiles, videoFiles, audioFiles } =
          classifyFiles(files);

        const validationError = validateFileTypes(
          files,
          setValidationError,
          newMessages,
          messages,
          history,
          uploadingFiles
        );

        if (validationError) {
          return false;
        }

        const totalFilesCount = (uploadingFiles?.length || 0) + files.length;
        const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
        if (
          isFreePlan &&
          totalFilesCount > 1 &&
          !isImageFile &&
          videoFiles?.length === 0 &&
          audioFiles?.length === 0
        ) {
          setMessageId!("documentChat.plan.max_count");
          return;
        }
        if (
          isFreePlan &&
          totalFilesCount > 1 &&
          !isImageFile &&
          videoFiles?.length === 0 &&
          docFiles?.length === 0
        ) {
          setMessageId!("audioChat.plan.max_count");
          return;
        }
        if (
          !isImageFile &&
          !validateMaxFileCount(audioFiles, 10, uploadingFiles)
        ) {
          setValidationError("audioChat.file.fileLimit");
          return;
        }
        if (
          (!isImageFile &&
            videoFiles.length === 0 &&
            audioFiles.length === 0 &&
            totalFilesCount > 5) ||
          (isImageFile && totalFilesCount > 20) ||
          (videoFiles.length > 0 && totalFilesCount > 1)
        ) {
          setErrorModal({
            message: isImageFile
              ? "ImageChat.file.fileLimit"
              : videoFiles.length > 0
                ? "validate.video.upload"
                : "documentChat.file.fileLimit",
            show: true,
          });
          return false;
        }

        // Validate video files
        if (
          videoFiles.length > 0 &&
          imageFiles.length === 0 &&
          docFiles.length === 0 &&
          (uploadingFiles?.length ?? 0) === 0 &&
          ((!history && newMessages.length === 0) ||
            messages[0]?.chatType === "video" ||
            newMessages[0]?.chatType === "video")
        ) {
          try {
            const duration = await validateVideoDurations(videoFiles[0]);
            if (typeof duration === "number" && duration > 1800) {
              setErrorModal({
                message: formatMessage({ id: "validate.video.duration" }),
                show: true,
              });
              return false;
            }
          } catch (error: any) {
            console.log(error);
          }
        }

        if (await hasInvalidAudioDuration(audioFiles)) {
          setValidationError("validate.audio.duration");
          return false;
        }

        return true;
      },
      [uploadingFiles, newMessages, chatItem, setErrorModal]
    );

    const MaxLimitReached = () => {
      setSelectedFile!(null);
      setUploadingFiles!([]);
      setIsMainScreenOpen!(true);
    };

    {
      /* /////////////////Upload To S3///////////////*/
    }

    const uploadFilesToS3 = async (files: File[]) => {
      const isImageFile = files[0].type.startsWith("image/");
      const isVideoFile = files[0].type.startsWith("video/");
      const isAudioFile = files[0].type.startsWith("audio/");

      if (
        !files[0].type.startsWith("image/") &&
        !files[0].type.startsWith("video/") &&
        !files[0]?.type.startsWith("audio/")
      ) {
        if (documentMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("documentChat.plan.max_count");
          MaxLimitReached();
          return;
        }
      }
      if (files[0]?.type.startsWith("video/")) {
        if (videoMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("videoChat.plan.max_count");
          MaxLimitReached();
          return;
        }
      }

      if (files[0]?.type.startsWith("audio/")) {
        if (audioMaxCountReached(currentPlan, userDetail)) {
          setMessageId!("audioChat.plan.max_count");
          MaxLimitReached();
          return;
        }
      }
      handleModelSwitch(
        gptModel,
        chatModels,
        isImageFile,
        isVideoFile,
        isAudioFile
      );

      const isValidFiles = await validateFileSelection(files);
      if (!isValidFiles) return; // Early return if validation fails

      const fileArray = Array.from(files);
      const fileType = files[0]?.type.startsWith("image/")
        ? "image"
        : files[0]?.type.startsWith("video/")
          ? "video"
          : "document";
      const validFiles =
        fileType === "image"
          ? validateImgFile(
              fileArray,
              setErrorMessage,
              claudAllowedFileSize,
              formatMessage
            )
          : validateFile(
              fileArray,
              setErrorModal,
              userDetail,
              setMessageId,
              setIsMainScreenOpen
            );

      if (validFiles) {
        setIsFileUploading!(true);
        await uploadToS3({
          fileArray,
          setUploadingFiles,
          fileType,
          setIsFileUploading,
          setFileS3Link,
        });
      }
    };

    const onConfirm = () =>
      push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
    const onCancel = () => setMessageId("");

    useEffect(() => {
      setIsReadOnly(getMessagesLoading || (width <= 768 && isOpen));
    }, [getMessagesLoading, width, isOpen]);

    useEffect(() => {
      if (
        textareaRef.current &&
        props.value === "" &&
        width <= 1200 &&
        width >= 768
      ) {
        textareaRef.current.style.height = "28px";
      }
    }, [width, gptModel]);

    const disabled =
      fileS3Link &&
      fileS3Link.length !== (uploadingFiles && uploadingFiles.length);

    return (
      <>
        <div className={styles.credits}>
          <ImageGenerationOptions getMessagesLoading={getMessagesLoading} />
          {!getMessagesLoading && gptModel?.type.includes("text") && (
            <CreditDeduction credit={credit} />
          )}
          <div
            className={classNames(styles.textAreaContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.activeborder]: true,
              [styles.file]: uploadingFiles && uploadingFiles.length > 0,
            })}
            onClick={() => {
              if (textareaRef.current) textareaRef.current.focus();
              onTextareaFocus?.();
            }}
          >
            <FileUploadPreview
              uploadingFiles={uploadingFiles}
              selectedFile={selectedFile}
              setUploadingFiles={setUploadingFiles}
              setFileS3Link={setFileS3Link}
            />

            <div className={`flex w-[100%] `}>
              <div
                className={styles.docUploadIcon}
                onClick={(e) => e.stopPropagation()}
              >
                <DocUpload
                  setSelectedFile={setSelectedFile}
                  setIsMainScreenOpen={setIsMainScreenOpen}
                  selectedFile={selectedFile}
                  setUploadUrl={setUploadUrl}
                  resetMessage={resetMessage}
                  message={message}
                  url={url}
                  setURL={setURL}
                  onSendMessage={onSendMessage}
                  setOpenHistory={setOpenHistory}
                  setUploadingFiles={setUploadingFiles}
                  setFileS3Link={setFileS3Link}
                  setIsFileUploading={setIsFileUploading}
                  uploadingFiles={uploadingFiles}
                  isAllowUploadFile={isAllowUploadFile}
                  chatItem={chatItem}
                  isloading={isloading}
                />
              </div>
              <TextareaAutosize
                ref={textareaRef}
                className={classNames(styles.textarea, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.placeholder]: isloading,
                  [styles.textAreaUploadFile]:
                    uploadingFiles && uploadingFiles?.length > 0,
                })}
                minRows={1}
                maxRows={25}
                placeholder={formatMessage({
                  id: "chatpage.searchField.placeholder",
                })}
                readOnly={isReadOnly}
                onPaste={handlePaste}
                {...props}
              />
              {isloading && (
                <div className={styles.loaderContainer}>
                  <Spinner icon={faCircleNotch} />
                </div>
              )}
              <div className={styles.chatfooterbtn}>
                <SendButton
                  isFieldFocused={true}
                  disabled={disabled}
                  data-testid="chat-footer-btn"
                  onMouseDown={handleMouseDown}
                  onClick={(event) => handleSubmit && handleSubmit(event)}
                />
              </div>
            </div>
          </div>
          {messageId && (
            <FileSizeLimitModal
              messageId={messageId}
              onCancel={onCancel}
              onClose={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </div>
      </>
    );
  }
);

import { CloseIcon } from "components/icons/CloseIcon";
import { UploadingFiles } from "pages/Workspaces/components/RichContext/UploadingFiles";
import { Notifications } from "pages/Workspaces/components/Notifications/Notifications";
import { UploadedFiles } from "pages/Workspaces/components/uploadedFiles/UploadedFiles";
import { UploadingUrl } from "pages/Workspaces/components/RichContext/UploadingUrl";
import { Modal } from "components";
import { Dispatch, SetStateAction, useState } from "react";
import CustomButton from "components/Button";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import styles from "../workSpaceConversation.module.scss";
import { useWindowSize } from "hooks/useWindowSize";
import { useEffectOnce } from "react-use";
import { setEmptyFile } from "redux/actions";
// import useRouter from "hooks/useRouter";
// import { useSelector } from "redux/hooks";
// import { RemoveWSFiles, UploadSource, WorkspaceUploadSrc } from "redux/actions";
import { useSelector } from "redux/hooks";

interface Iprops {
    setAddSource: Dispatch<SetStateAction<boolean>>;
}
const DurationButton = styled(CustomButton)`
background-color: #4338CA;
color: #fff;
flex-shrink: 0;
font-size: 12px;
line-height: 15px;
font-weight: 600;
border-radius: 4px;
padding: 6px 20px;
   @media (max-width:576px) {
        font-size: 11px;
        padding: 5px 16px;
     } 
`;

export const AddSource = ({ setAddSource }: Iprops) => {

    const [selectedOption, setSelectedOption] = useState("files");
    const { width } = useWindowSize();
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const isFreePlan = userDetail?.user.activeSubscription?.name === "Free";
    const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);

    useEffectOnce(()=> {
        setEmptyFile();
    })
    // const { pathname } = useRouter();


    const handleSource = () => {
        setAddSource(false)
    };
    const maxSources = isFreePlan ? 1 : 5;
    const usedSources = workSpaceFiles?.length || 0;

    return (
        <Modal onClose={() => setAddSource(false)} isPadding workSpace>
            <div className="flex flex-col gap-[14px]">
                <div className="flex justify-between">
                    <h3 className={`${width <= 576 ? "text-[14px]" : "text-[16px]"} text-[#525252] dark:text-[#fff] leading-[20px] font-semibold `}>
                        <FormattedMessage id="workspace.addsources.head" />
                    </h3>
                    <span className="cursor-pointer" onClick={() => setAddSource(false)}><CloseIcon workSpace={true} /></span>
                </div>
                <div className="flex justify-between" style={{ borderBottom: "0.4px solid #94A3B8 " }}>
                    <div className={`${width <= 576 ? "gap-[6px]" : " gap-[24px]"} flex`}>
                        <div
                            className={`leading-[17px] font-normal cursor-pointer pb-1 ${width <= 576 ? "text-[12px]" : "text-[14px]"} ${selectedOption === "files"
                                ? theme === "light"
                                    ? "text-[#4338CA]"
                                    : "text-[#527AE6]"
                                : theme === "light"
                                    ? "text-[#737373]"
                                    : "text-[rgba(255,255,255,0.9)]"
                                }`}
                            style={{
                                borderBottom: selectedOption === "files"
                                    ? `2px solid ${theme === "light" ? "#4338CA" : "#527AE6"}`
                                    : "none"
                            }}
                            onClick={() => setSelectedOption("files")}
                        >
                            <FormattedMessage id="workspace.addsources.files" />
                        </div>
                        <div
                            className={`leading-[17px] font-normal cursor-pointer pb-1 ${width <= 576 ? "text-[12px]" : "text-[14px]"} ${selectedOption === "urls"
                                ? theme === "light"
                                    ? "text-[#4338CA]"
                                    : "text-[#527AE6]"
                                : theme === "light"
                                    ? "text-[#737373]"
                                    : "text-[rgba(255,255,255,0.9)]"
                                }`} style={{
                                    borderBottom: selectedOption === "urls"
                                        ? `2px solid ${theme === "light" ? "#4338CA" : "#527AE6"}`
                                        : "none"
                                }}
                            onClick={() => setSelectedOption("urls")}
                        >
                            <FormattedMessage id="workspace.addsources.urls" />
                        </div>
                    </div>
                    <div className={` ${width <= 576 ? "text-[10px]" : "text-[12px]"} text-[#525252] dark:text-[rgba(255,255,255,0.9)] leading-[15px] font-normal `}>
                        <span className="underline">
                            <FormattedMessage id="workspace.addsource.usage" />
                        </span>
                        &nbsp;-&nbsp;
                        <FormattedMessage
                            id="workspace.addsource.subhead"
                            values={{ used: usedSources, max: maxSources }}
                        />
                    </div>
                </div>
                {selectedOption === "files" && <UploadingFiles />}
                {selectedOption === "urls" && <UploadingUrl />}
                <Notifications
                    notificationIds={[
                        "workspace.context.notification1",
                        "workspace.context.notification2",
                        "workspace.context.notification3",
                        "workspace.context.notification4",
                    ]}
                    headingId="workspace.context.notificationhead"
                />

                <UploadedFiles hideContainer={true} hideSubhead={true} />
            </div>
            <div className="flex justify-end pt-4">
                <DurationButton onClick={handleSource}>
                    <span className={styles.btn}>
                        <FormattedMessage id="workspace.context.source.btn" />
                    </span>
                </DurationButton>
            </div>
        </Modal >
    );
}
import { FormattedMessage } from "react-intl";
import styles from "./RichContext.module.scss";
import { UploadIcon } from "../icons/UploadIcon";
import CustomButton from "components/Button";
import styled from "styled-components";
import { useSelector } from "redux/hooks";
import { DragEvent } from "react";

import {
  classifyFiles,
  validateMaxFileCount,
  validateWSFile,
} from "utils/fileService";
import { addFile, UploadFile } from "redux/actions";
import { useState } from "react";
import { ErrorModal } from "pages/ChatPage/components/ErrorModal";
import { DocAllowedTypes, generateUniqueId } from "utils/constants";
import { documentMaxCountReached } from "utils/chat";
import { FileSizeLimitModal } from "pages/ChatPage/components/fileSizeLimitModal";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useWSFileUploader } from "utils/workSpaceS3";
import classNames from "classnames";

const DurationButton = styled(CustomButton)`
  background-color: #3730A3;
  color: #fff;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight:600;
  border-radius: 4px;
  padding: 6px 11px";
`;

export const UploadingFiles = () => {
  const { fileUPToS3 } = useWSFileUploader();
  const { push } = useRouter();
  const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );

  const [messageId, setMessageId] = useState<string>("");
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });

  const fileLimit =
    userDetail?.user.activeSubscription?.name === "Free"
      ? 10
      : userDetail?.user.activeSubscription?.name === "Standard"
        ? 50
        : 100;

  const setValidationError = (messageKey: string) => {
    setErrorModal({ message: messageKey, show: true });
  };

  const checkForFreeLimit = (files: File[], messageId: string) => {
    const isFreeUser = userDetail?.user.activeSubscription?.name === "Free";
    const totalFilesCount =
      (workSpaceFiles ? workSpaceFiles.length : 0) + files.length;
    if (isFreeUser && (files.length > 1 || totalFilesCount > 1)) {
      setMessageId?.(messageId);
      return false;
    }
  };

  const handleDragOver = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleFileUpload = async(files: FileList | null) => {
    if (!files?.length) return;

    const newFilesArray: File[] = Array.from(files);
    const { docFiles } = classifyFiles(newFilesArray);

    const validDocFiles = newFilesArray.filter((file) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return DocAllowedTypes.includes(fileExtension || "");
    });

    if (validDocFiles.length === 0) {
      setValidationError("documentChat.file.fileFormatImage");
      return;
    }

    if (
      docFiles.length > 0 &&
      !validateMaxFileCount(docFiles, 5, workSpaceFiles)
    ) {
      setValidationError("documentChat.file.fileLimit");
      return;
    }

    if (documentMaxCountReached(currentPlan, userDetail)) {
      setMessageId?.("documentChat.plan.max_count");
      return;
    }
    const isFree = checkForFreeLimit(
      newFilesArray,
      "documentChat.plan.max_count"
    );
    if (isFree === false) return;

    await uploadFilesToS3(newFilesArray);

  }

  const handleDrop = async (event: DragEvent<HTMLElement> | undefined) => {
    event?.preventDefault();
    const files = event?.dataTransfer?.files ?? null;
    handleFileUpload(files);
   
  };

const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  handleFileUpload(event.target.files);
};

  const uploadFilesToS3 = async (files: File[]) => {
    const uploadedFiles: UploadFile[] = [];

    for (const file of files) {
      const updatedFile: UploadFile = {
        id: generateUniqueId(),
        file,
        status: "validating",
        fileType: "document",
        errorMessage: "",
        progress: 0,
        fileSize: file.size / (1024 * 1024),
      };

      uploadedFiles.unshift(updatedFile);
      addFile(updatedFile);
    }

    const validFiles = validateWSFile(uploadedFiles, setErrorModal, userDetail);

    if (validFiles) {
      await fileUPToS3({ fileArray: uploadedFiles });
    }
  };

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const onCancel = () => setMessageId("");

  return (
    <>
      <div
        className={styles.fileWrapper}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <UploadIcon />
        <div className={styles.chooseFileWrapper}>
          <DurationButton onClick={() => document.getElementById("fileUploadInput")?.click()}>
            <span className={styles.btn}>
              <FormattedMessage id="workspace.context.btntext" />
            </span>
            <input
              id="fileUploadInput"
              type="file"
              multiple
              accept={DocAllowedTypes.map((type) => `.${type}`).join(",")}
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
          </DurationButton>
        </div>
          <div className={classNames(styles.heading, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                      })}>
        <FormattedMessage id="workspace.context.statement" values={{ fileLimit }} />
      </div>
      </div>

      {errorModal.show && (
        <div className={styles.containerCenter}>
          <ErrorModal
            message={errorModal.message}
            uploadURL={true}
            onClose={() => {
              setErrorModal({ message: "", show: false });
            }}
          />
        </div>
      )}

      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

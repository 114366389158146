import { useState, Fragment, Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import styles from "./MDNav.module.scss";

import { PlusIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { IChat } from "redux/actions";
import { useSelector } from "redux/hooks";
import { LogoutIcon } from "../AccountNav/icons";
import { logout } from "redux/actions";
import { Setting } from "../ChatNav/icons/setting";
import { ChatModel } from "../ChatNav/components/ChatModel";
import { Link } from "react-router-dom";
import { Credits } from "../ChatNav/components/Credits";
import styled from "styled-components";
import { RemoveIcon, TickIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons";
import { EditIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import MixpanelAnalytics from "utils/mixpanel";

interface IProps {
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

const WorkspaceName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: max-content;
  width: fit-content;
  flex-shrink: 1;
`;

const WorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 37%;
  min-width: 0;
   @media (max-width: break-points.$sm) {
       max-width: 34%;
    }
`;
export const MDNav = ({
  onStartNewChat,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {
  const { pathname, includeRoute, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const isShareChat = window.location.pathname.includes("share-chat");

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";
  const Id = pathname.split("/")[2];

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>('WorkSpaces');
  //Logout Function
  const [isloading, setIsloading] = useState<boolean>(false);
  const onLogout = () => {
    setIsloading(true);
    MixpanelAnalytics.trackLogout();
    logout()
      .then(() => {
        localStorage.removeItem('email');
        window.postMessage({ logout: true });
        MixpanelAnalytics.reset();
        setIsloading(false);
      })
      .catch((err: any) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setIsloading(false);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: any) => {
    setWorkspaceName(event.target.value);
  };

  if (includeRoute(RoutePaths.Chat) || includeRoute(RoutePaths.Workspaces)) {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          {isShareChat &&
            <Link to='/' className={styles.headerLogo} data-testid='share-header-logo'>
              <img src={require('../../../Sidebar/logo.png')} />
            </Link>
          }
          {(userDetail && userDetail.token && !includeRoute(RoutePaths.Workspaces)) &&
            <ChatModel toggleChatModelsModel={toggleChatModelsModel} chatItem={chatItem} />
          }
          {includeRoute(RoutePaths.Workspaces) && (
            <div className={classNames(styles.LogoContainer, "flex items-center gap-2")} >
              <div className={classNames(styles.WorkSpace, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
              })}>WorkSpaces </div>
              {Id && (
                <>
                  <span className="text-[#171717] dark:text-[#fff]">/</span>
                  {isEditing ? (
                    <span className={classNames(styles.inputWithIcons, "flex items-center flex-1 gap-2")}>
                      <input
                        className={classNames("flex-grow min-w-0", styles.inputField, {
                          [styles.light]: themeMode === "light",
                          [styles.dark]: themeMode === "dark",
                        })}
                        type="text"
                        value={workspaceName}
                        onChange={handleInputChange}
                        autoFocus
                      />
                      <span className="flex items-center gap-0">
                        <div data-testid="confirm-edit-btn" className="cursor-pointer">
                          <TickIcon EWorkSpace={true} />
                        </div>
                        <div data-testid="cancel-edit-btn" className="cursor-pointer" onClick={() => setIsEditing(false)}>
                          <RemoveIcon EWorkSpace={true} />
                        </div>
                      </span>
                    </span>
                  ) : (
                    <>
                      <WorkspaceWrapper>
                        <WorkspaceName className={classNames(styles.WorkSpace, {
                          [styles.light]: themeMode === "light",
                          [styles.dark]: themeMode === "dark",
                        })}>{workspaceName}</WorkspaceName>
                        <span className="flex items-center cursor-pointer" onClick={handleEditClick}>
                          <EditIcon EditWorkSpace={true} />
                        </span>
                      </WorkspaceWrapper>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          <div className={!isShareChat ? styles.menuContainer : styles.menuShareContainer}>
            {!isShareChat &&
              <>
                {!includeRoute(RoutePaths.Workspaces) && (
                  <>
                    <div
                      data-testid='md-setting-new-chat'
                      className={styles.icon}
                      onClick={() => {
                        onStartNewChat?.({ toChat: true });
                      }}
                    >
                      <PlusIcon
                        color="#71717a"
                      />
                    </div>
                    <div
                      data-testid='md-chat-setting-icon'
                      className={`${styles.icon} ${styles.settingIcon}`}
                      onClick={() => setChatSetting!(true)}
                    ><Setting />
                    </div>
                  </>
                )}
                <div>
                  <Credits mdNav={true} />
                </div>
              </>
            }
            {isShareChat &&
              <div className={styles.shareChatbtn}>
                <button data-testid='share-chat-try-deftgpt-free' className={styles.shareChatTryBtn}
                  onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                >
                  <FormattedMessage id='share.chat.header' />
                </button>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.teamMdNav]: includeRoute(RoutePaths.Team),
          })}
        >
          {(includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.CurrentPlan)) && (
            <div
              className={classNames(styles.pageTitle, {
                [styles.account]: includeRoute(RoutePaths.Account),
              })}
            >
              {includeRoute(RoutePaths.Team) ? `My ${pathname.split("/")[2].charAt(0).toUpperCase()}${pathname.split("/")[2].slice(1)}` : includeRoute(RoutePaths.CurrentPlan) ? "Current Plan" : pathname.split("/")[2]}
            </div>
          )}
          {!includeRoute(RoutePaths.Team) && <div className={styles.placeholder}></div>}
          {(includeRoute(RoutePaths.Account) || includeRoute(RoutePaths.Team) || includeRoute(RoutePaths.CurrentPlan)) && (
            <button
              className={classNames(styles.logoutButton, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
              })}
              onClick={onLogout}
              disabled={isloading}
              id="logout"
              data-testid='account-setting-logout'
            >
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                })}
              >
                <LogoutIcon theme={themeMode} />
              </div>
            </button>
          )}
        </div>

      </Fragment>
    );
  }
};
import { useIntl } from "react-intl";
import styles from "./RichContext.module.scss";
import CustomButton from "components/Button";
import styled from "styled-components";
import { Field, Formik, FieldProps } from "formik";
import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import { LabeledInput } from "components";
import { PlusIcon } from "../icons/RightSectionIcon";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { FileSizeLimitModal } from "pages/ChatPage/components/fileSizeLimitModal";
import {
  DocAllowedTypes,
  generateUniqueId,
  simulateSourceProgress,
} from "utils/constants";
import { useSelector } from "redux/hooks";
import { documentMaxCountReached } from "utils/chat";
import { getFileSize, validateFileType } from "utils/functions";
import validUrl from "valid-url";
import { useState } from "react";
import { ErrorModal } from "pages/ChatPage/components/ErrorModal";
import {
  addFile,
  AddUploadedFile,
  IUploadedFile,
  // IUploadedFile,
  updateErrorMessage,
  updateFileProgress,
  updateFileSize,
  updateFileStatus,
  UploadedFileRes,
  UploadFile,
  UploadSource,
  WorkspaceUploadSrc,
} from "redux/actions";

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 4px;
  margin-bottom: 0;
`;

const DurationButton = styled(CustomButton)`
  background-color: #3730A3;
  color: #fff;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 15px;
  font-weight:600;
  border-radius: 4px;
  padding: 6px 11px";
    @media (max-width:576px) {
        font-size: 11px;
         padding: 5px 16px;
     } 
`;

export const UploadingUrl = () => {
  const AllowedFileSize = 100;
  const FreePlanAllowedFileSize = 10;
  const StandardPlanAllowedFileSize = 50;

  const { push, pathname } = useRouter();
  const { formatMessage } = useIntl();
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector(
    (state) => state.planSubscriptionReducer.activePlan
  );
  const { workSpaceFiles, workSpaces } = useSelector(
    (state) => state.workSpaceReducer
  );

  const workSpaceId = pathname.split("/")[2];
  const planName = userDetail?.user.activeSubscription.name.toLowerCase();
  const [messageId, setMessageId] = useState<string>("");
  const [errorModal, setErrorModal] = useState<{
    message: string;
    show: boolean;
  }>({
    message: "",
    show: false,
  });

  const setValidationError = (messageKey: string) => {
    setErrorModal({
      message: formatMessage({ id: messageKey }),
      show: true,
    });
  };

  const onSubmit = async (inputUrl: string, resetForm: () => void) => {
    if (!inputUrl) return;

    const urlExtension = inputUrl?.split(".").pop()?.toLowerCase() || "";

    if (workSpaceFiles.length > 4) {
      setValidationError("documentChat.file.fileLimit");
      return;
    }

    if (planName === "free" && workSpaceFiles.length > 0) {
      setMessageId!("documentChat.plan.max_count");
      resetForm();
      return;
    }

    if (DocAllowedTypes.includes(urlExtension)) {
      if (documentMaxCountReached(currentPlan, userDetail)) {
        setMessageId!("documentChat.plan.max_count");
        resetForm();
        return;
      }
    }

    const FileSize = await getFileSize(inputUrl ? inputUrl : "");
    const file_id = generateUniqueId();
    if (await validateURL(inputUrl, resetForm, FileSize, file_id)) {
      const latestWorkSpaceId = workSpaces.length
        ? workSpaces[workSpaces.length - 1].id
        : 1;

      const data: WorkspaceUploadSrc = {
        source_type: "file",
        file_path: [inputUrl],
        workspace_id: workSpaceId ? Number(workSpaceId) : latestWorkSpaceId,
        size: FileSize,
      };

      updateFileStatus(file_id, "uploading");
      // Start progress simulation before UploadSource
      const progressInterval = simulateSourceProgress(file_id, 0, 99, 2000);

      UploadSource(data)
        .then((response) => {
          clearInterval(progressInterval);
          updateFileProgress(file_id, 100);
          updateFileStatus(file_id, "uploaded");

          const res = response as UploadedFileRes; // Type assertion
          const uploadedFile = res.data as IUploadedFile; 
          AddUploadedFile(uploadedFile);
        })
        .catch(() => {
          clearInterval(progressInterval);
        });
    }
  };

  const validateURL = async (
    inputUrl: string,
    resetForm: () => void,
    FileSize: number,
    file_id: string
  ): Promise<boolean> => {
    if (validUrl.isHttpsUri(inputUrl ? inputUrl : "")) {
      const commaIndex = inputUrl?.indexOf(",") ?? -1;
      if (commaIndex !== -1) {
        const textAfterComma = inputUrl?.slice(commaIndex + 1).trim();
        if (textAfterComma) {
          setValidationError("upload.url.validation");
          resetForm();
          return false;
        }
      }
      if (validateFileType({ url: inputUrl, types: DocAllowedTypes })) {
        resetForm();
        const emptyFile = new File([""], "EMpty.txt", { type: "text/plain" });
        const updatedFile: UploadFile = {
          id: file_id,
          file: emptyFile,
          status: "uploaded",
          fileType: "document",
          S3Link: inputUrl,
          errorMessage: "",
          progress: 0,
          fileSize: 0,
        };
        addFile(updatedFile);

        if (planName === "free" && FileSize > FreePlanAllowedFileSize) {
          updateErrorMessage(
            updatedFile.id,
            "error",
            `workSpace.file.size.limits`
          );
          resetForm();
          return false;
        } else if (
          planName === "standard" &&
          FileSize > StandardPlanAllowedFileSize
        ) {
          updateErrorMessage(
            updatedFile.id,
            "error",
            `workSpace.file.size.limits`
          );
          resetForm();
          return false;
        } else if (FileSize > AllowedFileSize) {
          resetForm();
          updateErrorMessage(
            updatedFile.id,
            "error",
            `workSpace.file.size.limit`
          );
          return false;
        }

        updateFileSize(updatedFile.id, FileSize);
      } else {
        setValidationError("documentChat.url.fileFormat");
        resetForm();
        return false;
      }
    } else {
      setValidationError("documentChat.url.validation");
      resetForm();
      return false;
    }

    return true;
  };

  const onConfirm = () =>
    push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const onCancel = () => setMessageId("");

  return (
    <>
      <div className={styles.urlWrapper}>
        <Formik
          onSubmit={(values, { resetForm }) => onSubmit(values.name, resetForm)}
          initialValues={{ name: "" }}
        >
          {({ handleSubmit }) => (
            <>
              <Form data-testid="workspace-form" style={{ width: "100%" }}>
                <FieldsRow>
                  <FieldRowItem>
                    <Field name="name">
                      {({ field, meta }: FieldProps<string>) => (
                        <LabeledInput
                          {...field}
                          workspace={true}
                          placeholder={formatMessage({
                            id: "workspace.context.placeholder",
                          })}
                          type="name"
                          error={!!meta.error && meta.touched}
                          message={
                            meta.touched &&
                            meta.error &&
                            formatMessage({ id: meta.error })
                          }
                          // autoFocus
                          variant={theme}
                          data-testid="workspace-create-name"
                        />
                      )}
                    </Field>
                  </FieldRowItem>
                </FieldsRow>
              </Form>
              <DurationButton type="button" onClick={() => handleSubmit()}>
                <span className={styles.btn}>
                  {" "}
                  <PlusIcon URL={true} />{" "}
                </span>
              </DurationButton>
            </>
          )}
        </Formik>
      </div>

      {errorModal.show && (
        <div className={styles.containerCenter}>
          <ErrorModal
            message={errorModal.message}
            uploadURL={true}
            onClose={() => {
              setErrorModal({ message: "", show: false });
            }}
          />
        </div>
      )}

      {messageId && (
        <FileSizeLimitModal
          messageId={messageId}
          onCancel={onCancel}
          onClose={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

// import { MainView } from "views/layout/MainView";
// import { FormattedMessage } from "react-intl";
// import styles from "./workspace.module.scss";
// import { WorkspaceIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons/WorkspaceIcon";
// import { ArrowIcon } from "./components/ArrowIcon";
// import { CreateWorkspace } from "./components/CreateWorkspace/CreateWorkspace";
// import classNames from "classnames";
// import { setShowCreateWorkspace } from "redux/actions/workSpace";
// import { useSelector } from "redux/hooks";
// // import { useWindowSize } from "hooks/useWindowSize";

// export const Workspace = () => {

//     // const { width } = useWindowSize();
//     const { showCreateWorkspace } = useSelector((state) => state.workSpaceReducer);

//     const handleLinkClick = () => {
//         setShowCreateWorkspace(true);
//     };
//     const handleClose = () => {
//         setShowCreateWorkspace(false);
//     };

//     const workspace = [
//         {
//             space_id: "space_1737704157996617",
//             space_name: "test",
//             members: 5,
//             lastUpdated: 'Updated today',
//         },
//         {
//             space_id: "space_1737704157996617",
//             space_name: "Mobile",
//             members: 36,
//             lastUpdated: 'Updated today'
//         },
//         {
//             space_id: "space_1737704157996617",
//             space_name: "Digital Marketers",
//             members: 36,
//             lastUpdated: 'Updated today'
//         },
//         {
//             space_id: "space_1737704157996617",
//             space_name: "B2B Discover - DeftGPT",
//             members: 36,
//             lastUpdated: 'Updated today'
//         },
//     ]

//     const confettiBall = String.fromCodePoint(0x1F38A);

//     return (
//         <>
//             <MainView>
//                 <div className="mx-auto mt-4 w-full flex-0 px-4 md:pl-8 lg:mt-6 md:pr-8 2xl:pr-14" >
//                     <div className={styles.headContainer}>
//                         <div className={styles.title}>
//                             <FormattedMessage id="workspaces.title" />
//                         </div>
//                         <h5 className={styles.countWorkspaces}>(3 of 5 workspaces)</h5>
//                     </div>
//                     <div className={`grid grid-cols-[repeat(auto-fill,minmax(278px,1fr))] gap-[12px] mt-6 overflow-hidden`}>
//                         <div className={styles.createContainer} onClick={handleLinkClick}>
//                             {confettiBall}
//                             <div className={styles.statement}> <FormattedMessage id="workspace.create.text" /> </div>
//                             <div className={styles.linkContainer} >
//                                 <div className={styles.link}><FormattedMessage id="workspace.create.link" /> </div>
//                                 <ArrowIcon />
//                             </div>
//                         </div>
//                         {workspace.map((space) => {
//                             return (
//                                 <div className={`${styles.createContainer} ${styles.solidBorder}`}>
//                                     <WorkspaceIcon workSpace={true}/>
//                                     <div className={styles.statement}> {space.space_name} </div>
//                                     <div className="flex justify-between items-center">
//                                         <div className={styles.indicatorContainer}>
//                                             <div className={styles.circleWhite}><div className={styles.circlegrey}></div></div>
//                                             <div className={classNames("-ml-[8px]", styles.circleWhite)}><div className={styles.circlegrey}></div></div>
//                                             <div className={classNames("-ml-[8px]", styles.circleWhite)}><div className={styles.circleBlue}> <span>3+</span> </div></div>
//                                         </div>
//                                         <div className="font-normal leading-[14.52px] text-[#737373]">Updated today</div>
//                                     </div>
//                                 </div>
//                             )
//                         })}
//                     </div>
//                 </div>

//                 <div className={styles.threadsContainer}>
//                     <div className={styles.title}>
//                         <FormattedMessage id="workspace.threads" />
//                     </div>
//                     <h5 className={styles.countWorkspaces}>(46 threads)</h5>
//                 </div>
//             </MainView>
//             {showCreateWorkspace && (
//                 <div >
//                     <CreateWorkspace onClose={handleClose} />
//                 </div>
//             )}
//         </>
//     );
// }

import { MainView } from "views/layout/MainView";
import { FormattedMessage } from "react-intl";
import styles from "./workspace.module.scss";
import { WorkspaceIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons/WorkspaceIcon";
import { ArrowIcon } from "./components/ArrowIcon";
import { CreateWorkspace } from "./components/CreateWorkspace/CreateWorkspace";
import classNames from "classnames";
import { setInProgressWS, setShowCreateWorkspace, ShowWorkspace } from "redux/actions/workSpace";
import { useSelector } from "redux/hooks";
import { FileSizeLimitModal } from "pages/ChatPage/components/fileSizeLimitModal";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { Spinner } from "components";
// import { useWindowSize } from "hooks/useWindowSize";

export const Workspace = () => {
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    // const { width } = useWindowSize();
    const { showCreateWorkspace, workSpaces } = useSelector((state) => state.workSpaceReducer);
    // Workspace limits based on subscription
    const [messageId, setMessageId] = useState<string>("");
    const [isloading, setIsloading] = useState<boolean>(true);
    const { push } = useRouter();

    const getWorkspaceLimit = () => {
        const plan = userDetail?.user.activeSubscription?.name;
        switch (plan) {
            case 'Free':
                return 1;
            case 'Standard':
            case 'Professional':
                return 3;
            case 'Team':
                return 5;
            case 'Business':
                return Infinity;
            default:
                return 0;
        }
    };

    const workspaceLimit = getWorkspaceLimit();

    const handleLinkClick = () => {
        if (workSpaces.length >= workspaceLimit) {
            setMessageId("workspace.limit.reached"); // Show FileSizeLimitModal
        } else {
            setInProgressWS("","","","");
            setShowCreateWorkspace(true); // Open CreateWorkspace modal
        }
    };

    const handleClose = () => {
        setShowCreateWorkspace(false);
    };

    const onConfirm = () =>
        push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);
    const onCancel = () => setMessageId("");

    const confettiBall = String.fromCodePoint(0x1F38A);

    useEffectOnce(() => {
        ShowWorkspace().finally(() => setIsloading(false)); // Set loading false after API response
    });
    return (
        <>
            <MainView>
                <div className="mx-auto mt-4 w-full flex-0 px-4 md:pl-8 lg:mt-6 md:pr-8 2xl:pr-14" >
                    {isloading ? (
                        <div className="flex justify-center items-center h-[100%]">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div className={styles.headContainer}>
                                <div className={classNames(styles.title, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}>
                                    <FormattedMessage id="workspaces.title" />
                                </div>
                                <h5 className={classNames(styles.countWorkspaces, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}>
                                    {/* (3 of 5 workspaces) */}
                                    ({workSpaces.length} of {workspaceLimit === Infinity ? '∞' : workspaceLimit} workspaces)
                                </h5>
                            </div>
                            <div className={`grid grid-cols-[repeat(auto-fill,minmax(278px,1fr))] gap-[12px] mt-6 overflow-hidden`}>

                                <div className={classNames(styles.createContainer, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })} onClick={handleLinkClick}>
                                    {confettiBall}
                                    <div className={classNames(styles.statement, {
                                        [styles.light]: theme === "light",
                                        [styles.dark]: theme === "dark",
                                    })}> <FormattedMessage id="workspace.create.text" /> </div>
                                    <div className={styles.linkContainer} >
                                        <div className={classNames(styles.link, {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        })}><FormattedMessage id="workspace.create.link" /> </div>
                                        <ArrowIcon theme={theme} />
                                    </div>
                                </div>
                                {workSpaces && workSpaces.map((space) => {
                                    return (
                                        <div className={`${styles.createContainer} ${classNames(styles.solidBorder, {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        })}`} onClick={() =>
                                            push(`/${RoutePaths.WorkspaceHistory}/${space.id}`)}>
                                            <WorkspaceIcon workSpace={true} />
                                            <div className={classNames(styles.statement, {
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}> {space.name} </div>
                                            <div className="flex justify-between items-center">
                                                {/* <div className={styles.indicatorContainer}>
                                                    <div className={classNames(styles.circleWhite, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}><div className={classNames(styles.circlegrey, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}></div></div>
                                                    <div className={classNames("-ml-[8px]", styles.circleWhite)}><div className={styles.circlegrey}></div></div>
                                                    <div className={classNames("-ml-[8px]", styles.circleWhite)}><div className={styles.circleBlue}> <span>3+</span> </div></div>
                                                </div> */}
                                                <div className={styles.indicatorContainer}>
                                                    <div className={classNames(styles.circleWhite, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}>
                                                        <div className={classNames(styles.circlegrey, {
                                                            [styles.light]: theme === "light",
                                                            [styles.dark]: theme === "dark",
                                                        })}></div>
                                                    </div>
                                                    <div className={classNames("-ml-[8px]", styles.circleWhite, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}>
                                                        <div className={classNames(styles.circlegrey, {
                                                            [styles.light]: theme === "light",
                                                            [styles.dark]: theme === "dark",
                                                        })}></div>
                                                    </div>
                                                    <div className={classNames("-ml-[8px]", styles.circleWhite, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}>
                                                        <div className={classNames(styles.circleBlue, {
                                                            [styles.light]: theme === "light",
                                                            [styles.dark]: theme === "dark",
                                                        })}>
                                                            <span>3+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="font-normal leading-[14.52px] text-[#737373] dark:text-[rgba(255,255,255,0.8)]">Updated today</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </div>

                {/* <div className={styles.threadsContainer}>
                    <div className={styles.title}>
                        <FormattedMessage id="workspace.threads" />
                    </div>
                    <h5 className={styles.countWorkspaces}>(46 threads)</h5>
                </div> */}
            </MainView >
            {showCreateWorkspace && (
                <div >
                    <CreateWorkspace onClose={handleClose} />
                </div>
            )
            }

            {
                messageId && (
                    <FileSizeLimitModal
                        messageId={messageId}
                        onCancel={onCancel}
                        onClose={onCancel}
                        onConfirm={onConfirm}
                    />
                )
            }
        </>
    );
}
import styles from "./uploadedFiles.module.scss";
import { RefetchIcon, TrashIcon } from "../icons/RightSectionIcon";
import { useSelector } from "redux/hooks";
import { DeleteUploadedFile, RefetchFile } from "redux/actions";
import {
  CSVIcon,
  EMLIcon,
  PDFIcon,
  TextIcon,
  PPTIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  PPTXIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import { Spinner } from "components";
import { useState } from "react";
import useRouter from "hooks/useRouter";
import { useEffectOnce } from "react-use";
import { ShowUploadSource } from "redux/actions";
import { useAppNotification } from "hooks/services/AppNotification";
import classNames from "classnames";

export const UploadedConversationFiles = () => {

  const { theme } = useSelector((state) => state.authReducer);
  const { triggerNotification } = useAppNotification();
  const { wsUploadedFiles } = useSelector((state) => state.workSpaceReducer);
  const [deletingFileIds, setDeletingFileIds] = useState<Set<number>>(
    new Set()
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { pathname } = useRouter();
  const workSpaceId = pathname.split("/")[2];

  useEffectOnce(() => {
    setLoading(true);
    ShowUploadSource(Number(workSpaceId))
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  });

  const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension) {
      case "pdf":
        return <PDFIcon />;
      case "csv":
        return <CSVIcon />;
      case "txt":
        return <TextIcon />;
      case "xlsx":
        return <XLSXIcon />;
      case "xls":
        return <XLSIcon />;
      case "srt":
        return <SRTIcon />;
      case "eml":
        return <EMLIcon />;
      case "pptx":
        return <PPTXIcon />;
      case "ppt":
        return <PPTIcon />;
      default:
        return <DocIcon />;
    }
  };

  const handleDeleteFile = async (id: number) => {
    setDeletingFileIds((prev) => new Set([...prev, id]));
    try {
      await DeleteUploadedFile(id);
    } finally {
      setDeletingFileIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  const handleRefetch = (id: number) => {
    RefetchFile(id);
  };

  return (
    <div className={styles.addSources}>
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner extraSmall />
        </div>
      ) : (
        wsUploadedFiles &&
        wsUploadedFiles.map((file) => {
          return (
            <div className={classNames(styles.filesContainer,{
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",    
                        })}>
              <div className={styles.fileWrapper}>
                <div>
                  {renderFileIcon(file?.file?.mime_type?.toLowerCase())}
                </div>
                <div className={styles.fileNameWrapper}>
                  <div  className={classNames(styles.fileName,{
                     [styles.light]: theme === "light",
                     [styles.dark]: theme === "dark",           
                  })}>
                    {" "}
                    {file?.file?.name?.split("-").slice(2).join("-")}
                  </div>

                  <span className="text-[12px] font-normal leading-[15px] text-[#737373] dark: text-[rgba(255,255,255,0.7)">
                    {file?.file?.size
                      ? `${file.file.size.toFixed(2)}  MB`
                      : "2.4 MB · Updated 2 mins ago"}
                  </span>
                </div>
                <div className="flex gap-[12px] justify-center cursor-pointer absolute right-[26px]">
                  <span onClick={() => handleRefetch(file?.id)}>
                    <RefetchIcon />
                  </span>
                  {deletingFileIds.has(file?.id) ? (
                    <span>
                      {" "}
                      <Spinner extraSmall />{" "}
                    </span>
                  ) : (
                    <span onClick={() => handleDeleteFile(file?.id)}>
                      <TrashIcon />
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

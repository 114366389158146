import _ from "lodash";
import { TYPES } from "../types";
import { produce } from "immer";
import { IUploadedFile, IWorkspace, UploadFile, InprogressWs } from "redux/actions";

export interface IWorkSpaceReducerState {
  showCreateWorkspace: boolean;
  workSpaceFiles: UploadFile[];
  workSpaces: IWorkspace[];
  wsUploadedFiles: IUploadedFile[];
  deletedFileId: string | null;
  inProgressWorkspace?: InprogressWs;
  currentConversation?: IWorkspace;
}

const state: IWorkSpaceReducerState = {
  showCreateWorkspace: false,
  workSpaceFiles: [],
  workSpaces: [],
  wsUploadedFiles: [],
  deletedFileId: null,
  inProgressWorkspace: {},
  currentConversation: {
    id: 0,
    created_at: '',
    description: '',
    instructions: '',
    name: '',
    settings: '',
    team_id: 0,
    updated_at: ''
  },
};

export const workSpaceReducer = (
  mState = { ...state },
  action: any
): IWorkSpaceReducerState => {
  switch (action.type) {
    case TYPES.CREATE_WORKSPACE:
      return produce(mState, (dState) => {
        dState.showCreateWorkspace = action.payload;
      });

    case TYPES.ADD_FILE:
      return produce(mState, (dState) => {
        if (!dState.workSpaceFiles) {
          dState.workSpaceFiles = [];
        }
        dState.workSpaceFiles.unshift(action.payload);
      });

    case TYPES.UPDATE_FILE_STATUS:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.status = action.payload.status;
        }
      });

    case TYPES.REMOVE_FILE:
      return produce(mState, (dState) => {
        dState.workSpaceFiles = dState.workSpaceFiles.filter(
          (file) => file.id !== action.payload
        );
        dState.deletedFileId = action.payload;
      });

      case TYPES.EMPTY_FILE:
        return produce(mState, (dState) => {
          dState.deletedFileId = action.payload;
        })

    case TYPES.UPDATE_FILE_S3_LINK:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.status = action.payload.status;
          file.S3Link = action.payload.S3Link;
        }
      });

    case TYPES.UPDATE_FILE_ERR_MESSAGE:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.status = action.payload.status;
          file.errorMessage = action.payload.errorMessage;
        }
      });

    case TYPES.UPDATE_FILE_PROGRESS:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.progress = action.payload.progress;
        }
      });

    case TYPES.UPDATE_FILE_SIZE:
      return produce(mState, (dState) => {
        const file = dState.workSpaceFiles.find(
          (f) => f.id === action.payload.id
        );
        if (file) {
          file.fileSize = action.payload.fileSize;
        }
      });

    case TYPES.ADD_WORKSPACE:
      return produce(mState, (dState) => {
        dState.workSpaces = [action.payload, ...dState.workSpaces];
      })

    case TYPES.WS_UPLOADED_FILES:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = [...action.payload];
      });

    case TYPES.REMOVE_WS_FILES:
      return produce(mState, (dState) => {
        dState.workSpaceFiles = action.pyload;
      });

    case TYPES.UPDATE_UPLOADED_FILE:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = [...dState.wsUploadedFiles, action.payload];
        // let index = dState.wsUploadedFiles.findIndex(
        //   (chat) => chat.id === action.payload.id
        // );
        // if (index >= 0) dState.wsUploadedFiles[index].name = action.payload.name;
      });

    case TYPES.WS_UPLOADED_FILES_REMOVE:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles = dState.wsUploadedFiles.filter(
          (file) => file.id !== action.payload.id
        );
      });

    case TYPES.ADD_FILE_UPLOADED:
      return produce(mState, (dState) => {
        dState.wsUploadedFiles.unshift(action.payload);
      });

      case  TYPES.UPLOADED_FILE_ID:
        return produce(mState, (dState) => {
          const file = dState.workSpaceFiles.find(
            (f) => f.id === action.payload.id
          );
          if (file) {
            file.uploaded_id = action.payload.uploaded_id;
          }
        });

    case TYPES.WS_CREATED:
      return produce(mState, (dState) => {
        dState.workSpaces = [...action.payload];
      });

    case TYPES.WS_Updated:
      return produce(mState, (dState) => {
        const workspace = dState.workSpaces.find(
          (ws) => ws.id === action.payload.id
        );
        if (workspace) {
          workspace.description = action.payload.description;
          workspace.instructions = action.payload.instructions;
        }
      });
    case TYPES.WS_PROGESSVALUES:
      return produce(mState, (dState) => {
        dState.inProgressWorkspace = {
          name: action.payload.name,
          emoji: action.payload.emoji,
          description: action.payload.description,
          instructions: action.payload.instructions,
        };
      });

    case TYPES.CURRENT_CONVERSATION:
      return produce(mState, (dState) => {
        dState.currentConversation = dState.workSpaces?.find(
          (conversation) => conversation.id === action.payload.id
        ) ; 
      });

    default:
      return { ...mState };
  }
};

export const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
            <g clip-path="url(#clip0_11595_829)">
                <path d="M15.2215 6.79707C16.6561 5.3625 16.6561 3.03926 15.2215 1.60469C13.952 0.335159 11.9512 0.17012 10.4913 1.21368L10.4506 1.2416C10.085 1.50313 10.0012 2.01094 10.2627 2.37403C10.5243 2.73711 11.0321 2.82344 11.3952 2.56192L11.4358 2.53399C12.2508 1.95254 13.3655 2.04395 14.0713 2.75235C14.8711 3.55215 14.8711 4.84707 14.0713 5.64688L11.2225 8.50078C10.4227 9.30059 9.12778 9.30059 8.32798 8.50078C7.61958 7.79239 7.52817 6.67774 8.10962 5.86524L8.13755 5.82461C8.39907 5.45899 8.31274 4.95118 7.94966 4.69219C7.58657 4.43321 7.07622 4.517 6.81724 4.88008L6.78931 4.92071C5.74321 6.37813 5.90825 8.37891 7.17778 9.64844C8.61235 11.083 10.9356 11.083 12.3702 9.64844L15.2215 6.79707ZM2.02856 6.20293C0.593994 7.6375 0.593994 9.96074 2.02856 11.3953C3.2981 12.6648 5.29888 12.8299 6.75884 11.7863L6.79946 11.7584C7.16509 11.4969 7.24888 10.9891 6.98735 10.626C6.72583 10.2629 6.21802 10.1766 5.85493 10.4381L5.81431 10.466C4.99927 11.0475 3.88462 10.9561 3.17876 10.2477C2.37896 9.44532 2.37896 8.15039 3.17876 7.35059L6.02759 4.49922C6.82739 3.69942 8.12231 3.69942 8.92212 4.49922C9.63052 5.20762 9.72192 6.32227 9.14048 7.13731L9.11255 7.17793C8.85103 7.54356 8.93735 8.05137 9.30044 8.31035C9.66353 8.56934 10.1739 8.48555 10.4329 8.12246L10.4608 8.08184C11.5069 6.62188 11.3418 4.6211 10.0723 3.35157C8.63774 1.917 6.3145 1.917 4.87993 3.35157L2.02856 6.20293Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_829">
                    <rect width="16.25" height="13" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
};
export const UploadFilesIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <g clip-path="url(#clip0_11595_825)">
                <path d="M9.74731 2.12773C9.12778 1.5082 8.12231 1.5082 7.50278 2.12773L2.83091 6.7996C1.76196 7.86855 1.76196 9.60019 2.83091 10.6691C3.89985 11.7381 5.63149 11.7381 6.70044 10.6691L10.5598 6.80976C10.8366 6.533 11.2885 6.533 11.5653 6.80976C11.842 7.08652 11.842 7.53847 11.5653 7.81523L7.70591 11.6746C6.08091 13.2996 3.45044 13.2996 1.82544 11.6746C0.200439 10.0496 0.200439 7.41913 1.82544 5.79413L6.49731 1.12226C7.6729 -0.0533264 9.5772 -0.0533264 10.7528 1.12226C11.9284 2.29785 11.9284 4.20214 10.7528 5.37773L6.28403 9.84648C5.55786 10.5726 4.37974 10.5726 3.65356 9.84648C2.92739 9.12031 2.92739 7.94218 3.65356 7.21601L7.30981 3.55976C7.58657 3.283 8.03852 3.283 8.31528 3.55976C8.59204 3.83652 8.59204 4.28847 8.31528 4.56523L4.65903 8.22148C4.48892 8.39159 4.48892 8.67089 4.65903 8.84101C4.82915 9.01113 5.10845 9.01113 5.27856 8.84101L9.74731 4.37226C10.3668 3.75273 10.3668 2.74726 9.74731 2.12773Z" fill="#94A3B8" />
            </g>
            <defs>
                <clipPath id="clip0_11595_825">
                    <rect width="11.375" height="13" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
};
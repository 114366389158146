import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN ?? '';

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV !== 'production',
    track_pageview: true
});

interface UserProperties {
    name?: string;
    email?: string;
    createdAt?: string;
    avatar?: string;
}

class MixpanelAnalytics {
    static identify(userId: string, userProperties: UserProperties = {}) {
        if (userId) {
            mixpanel.identify(userId);
            
            const userProfile = {
                $name: userProperties.name,
                $email: userProperties.email,
                $created: userProperties.createdAt,
                $avatar: userProperties.avatar,
                ...userProperties
            };
            
            mixpanel.people.set(userProfile);
        }
    }

    static trackPageView(pageUrl:string) {
        mixpanel.track('$page_view', {
            page_url: pageUrl
        });
    }

    static trackSignup(method = 'email') {
        mixpanel.track('$signup', {
            signup_method: method
        });
    }

    static trackLogin(method = 'email') {
        mixpanel.track('$login', {
            login_method: method
        });
    }

    static trackLogout() {
        mixpanel.track('$logout');
    }

    static reset() {
        mixpanel.reset();
    }
}

export default MixpanelAnalytics;
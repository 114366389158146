import { useState, useCallback, useRef, useEffect } from "react";
import axios, { CancelTokenSource } from "axios";
import {
  // delRemoveFile,
  updateFileProgress,
} from "redux/actions";
import { useSelector } from "redux/hooks";

type FileUploadHook = {
  uploadProgress: number | null;
  uploadFile: (data: {
    file: File;
    preSignedUrl?: string;
    id?: string;
  }) => Promise<any>;
  cancelUpload: (id?: string) => Promise<boolean>;
};

export const useS3FileUpload = (): FileUploadHook => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const cancelTokens = useRef<Map<string, CancelTokenSource>>(new Map()); // Map of cancel tokens
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { deletedFileId } = useSelector((state) => state.workSpaceReducer);

   // Handle deletion of uploaded files
   useEffect(() => {
    if (deletedFileId && cancelTokens.current.has(deletedFileId)) {
      cancelTokens.current.get(deletedFileId)?.cancel(`Upload canceled for file ID: ${deletedFileId}`);
      cancelTokens.current.delete(deletedFileId);
    }
  }, [deletedFileId]);

  const uploadFile = useCallback(
    async ({
      file,
      preSignedUrl,
      id,
    }: {
      file: File;
      preSignedUrl?: string;
      id?: string;
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const fileId = id ?? crypto.randomUUID(); // Generate an ID if not provided
        const source = axios.CancelToken.source();
        cancelTokens.current.set(fileId, source); // Store cancel token for this file
        setIsUploading(true);

        try {
          let result;
          if (preSignedUrl) {
            result = await axios.put(preSignedUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent) => {
                const total = progressEvent.total || 0;
                const progress = Math.round(
                  (progressEvent.loaded / total) * 90
                );
                setUploadProgress(progress);
                if (id) {
                  updateFileProgress(id, progress);
                }
              },
              cancelToken: source.token,
            });
          } else {
            result = await axios.put("", file, {
              headers: {
                "Content-Type": file.type,
              },
              onUploadProgress: (progressEvent) => {
                const total = progressEvent.total || 0;
                const progress = Math.round(
                  (progressEvent.loaded / total) * 100
                );
                setUploadProgress(progress);
              },
              cancelToken: source.token,
            });
          }
          resolve(result);

        cancelTokens.current.delete(fileId); // Remove token after completion
        } catch (error) {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            reject(error);
          }
        } finally {
          setUploadProgress(0);
          setIsUploading(false);
        }
      });
    },
    []
  );

 
  const cancelUpload = useCallback(async (id?: string) => {
    if (id) {
      // Cancel a specific file upload
      if (cancelTokens.current.has(id)) {
        cancelTokens.current.get(id)?.cancel(`File upload canceled for file ID: ${id}`);
        cancelTokens.current.delete(id);
        return true;
      }
      return false;
    } else {
      if(isUploading) {
      // Cancel all uploads
      cancelTokens.current.forEach((token, fileId) => {
        token.cancel(`File upload canceled for file ID: ${fileId}`);
      });
      cancelTokens.current.clear();
      return true;
    } 
    return false;
  }

  }, [cancelTokens]);

  return {
    uploadProgress,
    uploadFile,
    cancelUpload,
  };
};

import { useS3FileUpload } from "hooks/services/AmazonServices";
import { useAppNotification } from "hooks/services/AppNotification";
import {
  AddUploadedFile,
  generatePresignedURL,
  updateFileProgress,
  updateFileS3Link,
  updateFileStatus,
  UploadFile,
  UploadSource,
  WorkspaceUploadSrc,
  UploadedFileRes,
  IUploadedFile,
  AddUploadedId,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import useRouter from "hooks/useRouter";
import { simulateSourceProgress } from "./constants";
import { useEffect, useRef } from "react";
import axios, { CancelTokenSource } from "axios";

interface IProp {
  fileArray: UploadFile[];
}

export const useWSFileUploader = () => {
  const { pathname } = useRouter();
  const workSpaceId = pathname.split("/")[2];
  const { uploadFile } = useS3FileUpload();
  const { triggerNotification } = useAppNotification();
  const { workSpaceFiles, workSpaces, deletedFileId } = useSelector(
    (state) => state.workSpaceReducer
  );
  // Store cancel tokens for each file
const cancelTokenMap = useRef(new Map<string, CancelTokenSource>());

  useEffect(() => {
    if (deletedFileId) {
        const cancelSource = cancelTokenMap.current.get(deletedFileId);
        if (cancelSource) {
          cancelSource.cancel(`Upload canceled for file ID: ${deletedFileId}`);
          cancelTokenMap.current.delete(deletedFileId);
        }
    }
  }, [deletedFileId]);

  const fileUPToS3 = async ({ fileArray }: IProp) => {
    const preSignedURLPromises = fileArray.map((file) => {
      updateFileStatus(file.id, "uploading");
      return generatePresignedURL({
        name: `website-${new Date().getTime()}-${file.file.name}`,
      })
        .then((preSignedRes: any) => ({
          file,
          preSignedURL: new URL(preSignedRes.data.url),
        }))
        .catch((err) => {
          triggerNotification({
            message: err?.data?.message,
            type: "error",
          });
          workSpaceFiles.forEach((files) => {
            if (file.file === files.file) updateFileStatus(files.id, "error");
          });

          return null;
        });
    });
    const preSignedURLs = await Promise.all(preSignedURLPromises);
    if (preSignedURLs.some((item) => item === null)) {
      return;
    }
    const validPreSignedURLs = preSignedURLs as {
      file: UploadFile;
      preSignedURL: URL;
    }[];
    const uploadPromises = validPreSignedURLs.map(({ file, preSignedURL }) => {
      if(deletedFileId === (file.id)){
        return null;
      }
      updateFileStatus(file.id, "uploading");
      return uploadFile({
        file: file.file,
        preSignedUrl: preSignedURL.href,
        id: file.id,
      })
        .then(() => {
          // Start progress simulation before UploadSource
          const progressInterval = simulateSourceProgress(
            file.id,
            90,
            100,
            2000
          );

          if(deletedFileId?.includes(file.id)){
            return;
          }

          const latestWorkSpaceId = workSpaces.length
            ? workSpaces[workSpaces.length - 1].id
            : 1;

          const data: WorkspaceUploadSrc = {
            source_type: "file",
            file_path: [`${preSignedURL?.origin}${preSignedURL?.pathname}`],
            workspace_id: workSpaceId? Number(workSpaceId) : latestWorkSpaceId,
            size: file.fileSize,
          };

      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenMap.current.set(file.id, cancelTokenSource);

          UploadSource(data, { cancelToken: cancelTokenSource.token })
            .then((response) => { 
              const res = response as UploadedFileRes; // Type assertion
              const uploadedFile = res.data as IUploadedFile; 
          
              if(workSpaceId) {
                AddUploadedFile(uploadedFile);
              }
              clearInterval(progressInterval);
              updateFileProgress(file.id, 100);
              updateFileS3Link(
                file.id,
                "uploaded",
                `${preSignedURL?.origin}${preSignedURL?.pathname}`
              );
              AddUploadedId(file.id, uploadedFile.id);
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                console.log(`UploadSource request canceled for file ID: ${file.id}`);
              } else {
              triggerNotification({
                message: error?.data?.message,
                type: "error",
              });
            }
            });

          return { preSignedURL };
        })
        .catch((err) => {
          triggerNotification({
            message: err?.data?.message,
            type: "error",
          });
          updateFileStatus(file.id, "error");
          return null;
        });
    });

    const uploadedFiles = await Promise.all(uploadPromises);
    if (uploadedFiles.some((item) => item === null)) {
      return;
    }
  };

  return { fileUPToS3 };
};

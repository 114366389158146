import _ from "lodash";
import { TYPES } from "../types";
import {
  BotSettings,
  DropdownOption,
  IChat,
  IChatSetting,
  IMessage,
  IPrompt,
  IRollBotSetting,
  IShareChat,
  pagination,
  Regenerate,
} from "../actions/chatInterface";
import { produce } from "immer";

export interface IChatReducerState {
  newMessages: IMessage[];
  chats: IChat[];
  messages: IMessage[];
  communityPrompts: IPrompt[];
  userPrompts: IPrompt[];
  shareMessages: IShareChat;
  chatSetting: IChatSetting;
  rollBotSetting: IRollBotSetting;
  RollBot: BotSettings;
  PrivateChat: boolean;
  RememberSetting: boolean;
  RememberType: string;
  dropdownOptions: DropdownOption[];
  containerHeight: number;
  errorDisplay: boolean;
  SaveALLChat: IChat[];
  pagination: pagination;
  viewHistory: boolean;
  searchWord: string;
  addHistory: string;
  scrollMessageId: number;
  scrollId: boolean;
  chathistory: IChat[];
  newChatQues: IMessage[];
  messagesPagination: pagination;
  isLoadMoreMessages: boolean;
  regenerateModel: Regenerate;
  chatLoading: boolean;
  AdminDelErrorMsg: boolean;
  creditLoad: boolean;
  currentMessageInd: number;
}

const state: IChatReducerState = {
  newMessages: [],
  chats: [],
  messages: [],
  communityPrompts: [],
  userPrompts: [],
  shareMessages: {
    id: 0,
    chat_id: 0,
    model_id: 0,
    token: "",
    chat_type: '',
    messages: [],
    created_at: "",
    updated_at: "",
  },
  chatSetting: {
    real_time_results: false,
    related_questions: false,
    send_message_with_enter: false,
  },
  rollBotSetting: {
    language: '',
    length: '',
    output_format: '',
    style_of_writing: '',
    tone: ''
  },
  RollBot: {
    writingStyles: [],
    languages: [],
    outputFormats: [],
    responseLengths: [],
    tones: [],
  },
  PrivateChat: false,
  RememberSetting: false,
  RememberType: "",
  dropdownOptions:
    [{
      key: "output_format",
      label: "Output Format",
      value: "outputFormats",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "tone",
      label: "Tone",
      value: "tones",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "style_of_writing",
      label: "Writing Style",
      value: "writingStyles",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "language",
      label: "Language",
      value: "language",
      options: [{ label: 'Default', value: 'default' }],
    },
    {
      key: "length",
      label: "Length",
      value: "responseLengths",
      options: [{ label: 'Default', value: 'default' }],
    }],
  containerHeight: 0,
  errorDisplay: false,
  SaveALLChat: [],
  pagination: {
    page: 0,
    lastPage: 0,
  },
  viewHistory: true,
  searchWord: '',
  addHistory: '',
  scrollMessageId: 0,
  scrollId: false,
  chathistory: [],
  newChatQues: [],
  messagesPagination: {
    page: 0,
    lastPage: 0,
  },
  isLoadMoreMessages: false,
  regenerateModel: {
    modelName: '',
    loader: false,
  },
  chatLoading: false,
  AdminDelErrorMsg: false,
  creditLoad: false,
  currentMessageInd: 0,
};

export const chatReducer = (
  mState = { ...state },
  action: any
): IChatReducerState => {
  switch (action.type) {
    case TYPES.START_NEW_CHAT:
      return produce(mState, (dState) => {
        dState.newMessages = action.payload;
      });

    case TYPES.NEW_CHAT:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message) => {
          if (message?.isNew === true) {
            const { isNew, ...rest } = message;
            return { ...rest, isNew: false };
          } else {
            return message;
          }
        });
        dState.newMessages.push(action.payload);
        dState.messages = [];
      });

    case TYPES.ANSWER_NEW_CHAT:
      return produce(mState, (dState) => {
        if (
          !action.payload.chat_id &&
          !action.payload.content &&
          action.payload.images.length === 0
        ) {
          dState.newMessages = [...dState.newMessages, action.payload];
        } else if (action.payload.images.length > 0) {
          dState.newMessages[dState.newMessages.length - 1].id =
            action.payload.id;
          dState.newMessages[dState.newMessages.length - 1].chat_id =
            action.payload.chat_id;
          dState.newMessages[dState.newMessages.length - 1].images =
            action.payload.images;
          dState.newMessages[
            dState.newMessages.length - 1
          ].regenerate_model_id = action.payload.regenerate_model_id;
          dState.newMessages[dState.newMessages.length - 1].model_id = action.payload.model_id;
        } else if (action.payload.chat_id && !action.payload.content) {
          dState.newMessages[dState.newMessages.length - 1].id =
            action.payload.id;
          dState.newMessages[dState.newMessages.length - 1].chat_id =
            action.payload.chat_id;
          dState.newMessages[
            dState.newMessages.length - 1
          ].regenerate_model_id = action.payload.regenerate_model_id;
          dState.newMessages[dState.newMessages.length - 1].model_id = action.payload.model_id;
        } else {
          dState.newMessages[dState.newMessages.length - 1].content +=
            action.payload.content;
        }
        dState.messages = [];
      });

    case TYPES.REGULAR_CHAT:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message) => {
          if (message.isNew === true) {
            const { isNew, ...rest } = message;
            return { ...rest, isNew: false };
          } else {
            return message;
          }
        });
        dState.messages.push(action.payload);
      });

    case TYPES.ADD_MESSAGE:
      return produce(mState, (dState) => {
        dState.chathistory = dState.chathistory.map(chat =>
          chat.id === action.payload.chatId
            ? {
              ...chat,
              messages: [...(chat.messages ?? []), action.payload.newMessage],
            }
            : chat
        );
      });

    case TYPES.UPDATE_MESSAGE:
      return produce(mState, (dState) => {
        dState.chathistory = dState.chathistory.map(chat =>
          chat.id === action.payload.chatId
            ? {
              ...chat,
              messages: chat?.messages?.map((message) => {
                if (message.type === "user" && !message.id) {
                  message = { ...message, ...action.payload.newMessage };
                }
                return message;
              })
            }
            : chat
        );
      });

    case TYPES.UPDATE_ANSWER:
      return produce(mState, (dState) => {
        dState.chathistory = dState.chathistory.map(chat =>
          chat.id === action.payload.chatId
            ? {
              ...chat,
              messages: chat?.messages?.map((message) => {
                if (message.id === action.payload.assistantId) {
                  message = { ...message, content: message.content + action.payload.message.content }
                }
                return message;
              })
            }
            : chat
        );
      });

    case TYPES.UPDATE_ASSISTANT_ANSWER:
      return produce(mState, (dState) => {
        dState.newChatQues = dState.newChatQues.map((message) => {
          if (message.id === action.payload.assistantId) {
            message = { ...message, content: message.content + action.payload.message.content }
          }
          return message;
        })
      });

    case TYPES.NEW_CHAT_QUESTION:
      return produce(mState, (dState) => {
        dState.newChatQues = [action.payload];
      });

    case TYPES.UPDATE_CHAT_QUESTION:
      return produce(mState, (dState) => {
        if (action.payload.type === 'assistant') {
          dState.newChatQues = [...dState.newChatQues, action.payload];
        }
        else {
          dState.newChatQues = dState?.newChatQues?.map((message) => {
            if (message.type === "user" && !message.id) {
              message = { ...message, ...action.payload };
            }
            return message;
          })
        }
      });

    case TYPES.REMOVE_CHAT_QUESTION:
      return produce(mState, (dState) => {
        dState.newChatQues = action.payload;
      });

    case TYPES.ADD_MESSAGE_HISTORY:
      return produce(mState, (dState) => {

        dState.chathistory = dState.chathistory.map(chat => {
          if (chat.id === action.payload && dState.newChatQues[0]?.chat_id === action.payload) {
            return {
              ...chat,
              messages: dState.newChatQues, // Update with new messages
            };
          } else {
            // If the condition is false, return the original chat object unchanged
            return chat;
          }
        });
      });

    case TYPES.UPDATED_NEW_REGULAR_CHAT:
      return produce(mState, (dState) => {
        for (let i = dState.newMessages.length - 1; i >= 0; i--) {
          const message = dState.newMessages[i];
          if (message.type === "user" && !message.id) {
            dState.newMessages[i] = { ...message, ...action.payload };
            break; // Only update the last matching message
          }
        }
      });

    case TYPES.UPDATE_REGULAR_CHAT:
      return produce(mState, (dState) => {
        for (let i = dState.messages.length - 1; i >= 0; i--) {
          const message = dState.messages[i];
          if (message.type === "user" && !message.id) {
            dState.messages[i] = { ...message, ...action.payload };
            break; // Only update the last matching message
          }
        }
      });

    case TYPES.ANSWER_REGULAR_CHAT:
      return produce(mState, (dState) => {
        if (
          !action.payload.chat_id &&
          !action.payload.content &&
          action.payload.images.length === 0
        ) {
          dState.messages = [...dState.messages, action.payload];
        } else if (action.payload.chat_id && action.payload.images.length > 0) {
          dState.messages[dState.messages.length - 1].id = action.payload.id;
          dState.messages[dState.messages.length - 1].chat_id =
            action.payload.chat_id;
          dState.messages[dState.messages.length - 1].images =
            action.payload.images;
          dState.messages[dState.messages.length - 1].regenerate_model_id =
            action.payload.regenerate_model_id;
          dState.messages[dState.messages.length - 1].model_id = action.payload.model_id;
        } else if (action.payload.chat_id && !action.payload.content) {
          dState.messages[dState.messages.length - 1].id = action.payload.id;
          dState.messages[dState.messages.length - 1].chat_id =
            action.payload.chat_id;
          dState.messages[dState.messages.length - 1].regenerate_model_id =
            action.payload.regenerate_model_id;
          dState.messages[dState.messages.length - 1].model_id = action.payload.model_id;
        } else {
          dState.messages[dState.messages.length - 1].content +=
            action.payload.content;
        }
      });

    case TYPES.GENERATE_IMAGE_ERROR:
      return produce(mState, (dState) => {
        const { message, messageViewType } = action.payload;
        if (
          messageViewType === "new" &&
          dState.newMessages[dState.newMessages.length - 1]
        ) {
          dState.newMessages[dState.newMessages.length - 1].content = message;
        }
        if (
          messageViewType === "history" &&
          dState.messages[dState.messages.length - 1]
        ) {
          dState.messages[dState.messages.length - 1].content = message;
        }
      });

    case TYPES.UPSCALE_IMAGE:
      return produce(mState, (dState) => {
        const { messageViewType, message_id, image, upscaledImage } =
          action.payload;
        if (messageViewType === "new") {
          const index = dState.newMessages.findIndex(
            (message) => message.id === message_id
          );
          if (index >= 0) {
            const upscaledimageIndex = dState.newMessages[
              index
            ].images.findIndex((img) => img.id === upscaledImage.image_id);
            if (upscaledimageIndex >= 0) {
              dState.newMessages[index].images[
                upscaledimageIndex
              ].upscaled_images = [upscaledImage];
              dState.newMessages[index].images = [
                ...dState.newMessages[index].images,
                image,
              ];
            }
          }
        }
        if (messageViewType === "history") {
          const index = dState.messages.findIndex(
            (message) => message.id === message_id
          );
          if (index >= 0) {
            const upscaledimageIndex = dState.messages[index].images.findIndex(
              (img) => img.id === upscaledImage.image_id
            );
            if (upscaledimageIndex >= 0) {
              dState.messages[index].images[
                upscaledimageIndex
              ].upscaled_images = [upscaledImage];
              dState.messages[index].images = [
                ...dState.messages[index].images,
                image,
              ];
            }
          }
        }
      });

    case TYPES.GET_ALL_CHATS:
      return produce(mState, (dState) => {
        dState.chats = [...action.payload.reverse()];
      });

    case TYPES.GET_ALL_CHATS_HISTORY:
      return produce(mState, (dState) => {
        dState.chathistory = [...dState.chathistory, ...action.payload];
      });

    case TYPES.SAVE_ALL_CHATS:
      return produce(mState, (dState) => {
        dState.SaveALLChat = [...action.payload];
      })

    case TYPES.SET_PAGINATION:
      return produce(mState, (dState) => {
        dState.pagination = { page: action.payload.page, lastPage: action.payload.LastPage }
      })

    case TYPES.DELETE_ALL_CHATS:
      return produce(mState, (dState) => {
        dState.SaveALLChat = [];
        dState.chathistory = [];
      })

    case TYPES.DELETE_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats = dState.chats.filter(
          (chat) => chat.id !== action.payload.id
        );
        dState.chathistory = dState.chathistory.filter(
          (chat) => chat.id !== action.payload.id
        );
      });

    case TYPES.DELETE_SELECTED_CHATS:
      return produce(mState, (dState) => {
        dState.chathistory = dState.chathistory.filter(
          (chatItem) => !action.payload.selectedChatIds.includes(chatItem.id)
        );
      })

    case TYPES.DELETE_MULTIPLE_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats = dState.chats.filter(
          (chat) => chat.id !== action.payload.includes(chat.id)
        );

      });

    case TYPES.UPDATE_CHAT_HISTORY:
      return produce(mState, (dState) => {

        let Index = dState.chathistory.findIndex(
          (chat) => chat.id === action.payload.id
        );
        if (Index >= 0) dState.chathistory[Index].topic = action.payload.topic;
      });

    case TYPES.GET_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.messages = [...action.payload, ...dState.messages];
        dState.newMessages = [];
      });

    case TYPES.LOAD_MESSAGES:
      return produce(mState, (dState) => {
        dState.isLoadMoreMessages = action.payload;
      })

    case TYPES.MESSAGES_PAGINATION:
      return produce(mState, (dState) => {
        dState.messagesPagination = { page: action.payload.current_page, lastPage: action.payload.last_page }
      })

    case TYPES.UPDATE_MESSAGES_PAGINATION:
      return produce(mState, (dState) => {
        dState.messagesPagination = { page: action.payload.current_page, lastPage: dState.messagesPagination.lastPage }
      })

    case TYPES.ADD_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.chats.unshift(action.payload);
        dState.chathistory.unshift(action.payload);
      });

    case TYPES.SAVE_FILE_PATH:
      return produce(mState, (dState) => {
        dState.chats = [action.payload.chatItem, ...dState.chats];
        dState.newMessages = action.payload.message;
      });

    case TYPES.REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message) => {
          if (message?.id === action.payload) {
            return _.omit(message, "related_questions");
          } else {
            return message;
          }
        });
      });

    case TYPES.REMOVE_RELATED_QUESTIONS_FROM_MESSAGES:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message) => {
          if (message.id === action.payload) {
            return _.omit(message, "related_questions");
          } else {
            return message;
          }
        });
      });

    case TYPES.REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages.splice(-1);
      });

    case TYPES.REMOVE_LAST_MESSAGE_FROM_MESSAGES:
      return produce(mState, (dState) => {
        dState.messages.splice(-1);
      });

    case TYPES.GET_GENERATED_QUESTIONS_NEW:
      return produce(mState, (dState) => {
        dState.newMessages = dState.newMessages.map((message, index) => {
          if (dState.newMessages.length === index + 1) {
            return {
              ...message,
              related_questions: action.payload,
            };
          } else {
            return message;
          }
        });
      });

    case TYPES.GET_GENERATED_QUESTIONS_HISTORY:
      return produce(mState, (dState) => {
        dState.messages = dState.messages.map((message, index) => {
          if (dState.messages.length === index + 1) {
            return {
              ...message,
              related_questions: action.payload,
            };
          } else {
            return message;
          }
        });
      });

    case TYPES.GET_ALL_PROMPT:
      return produce(mState, (dState) => {
        dState.communityPrompts = [...action.payload];
      });

    case TYPES.GET_USER_PROMPT:
      return produce(mState, (dState) => {
        dState.userPrompts = [...action.payload];
      });

    case TYPES.DELETE_USER_PROMPT:
      return produce(mState, (dState) => {
        dState.userPrompts = dState.userPrompts.filter(
          (chat) => chat.id !== action.payload.id
        );
      })

    case TYPES.UPDATE_USER_PROMPT:
      return produce(mState, (dState) => {
        let index = dState.userPrompts.findIndex(
          (chat) => chat.id === action.payload.id
        );
        if (index >= 0) {
          dState.userPrompts[index].title = action.payload.data.title;
          dState.userPrompts[index].prompt = action.payload.data.prompt;
        }
      });

    case TYPES.GET_SHARE_CHAT:
      return produce(mState, (dState) => {
        dState.shareMessages = action.payload;
      });

    case TYPES.GET_CHAT_SETTING:
      return produce(mState, (dState) => {
        dState.chatSetting = action.payload;
      });

    case TYPES.GET_ROLL_BOT_SETTING:
      return produce(mState, (dstate) => {
        dstate.rollBotSetting = action.payload
      })

    case TYPES.GET_ROLL_BOT:
      return produce(mState, (dstate) => {
        dstate.RollBot = action.payload
      })

    case TYPES.SET_PRIVATE_CHAT:
      return produce(mState, (dState) => {
        dState.PrivateChat = action.payload;
      });

    case TYPES.SET_REMEMBER_SETTING:
      return produce(mState, (dState) => {
        dState.RememberSetting = action.payload;
      })

    case TYPES.SET_REMEMBER_Chat_Type:
      return produce(mState, (dState) => {
        dState.RememberType = action.payload;
      })

    case TYPES.SET_DROP_DOWN_OPTIONS: {
      return produce(mState, (dState) => {
        dState.dropdownOptions = action.payload;
      });
    }

    case TYPES.UPDATE_DROP_DOWN_OPTIONS: {
      return produce(mState, (dState) => {
        const languageOption = dState.dropdownOptions.find(option => option.key === 'language');

        if (languageOption) {
          languageOption.options = [
            { label: 'Default', value: 'default' },
            ...action.payload.languages
          ];
        }
      });
    }

    case TYPES.SET_DRAG_FILE:
      return produce(mState, (dState) => {
        dState.errorDisplay = action.payload;
      })

    case TYPES.SET_CONTAINER_HEIGHT:
      return produce(mState, (dState) => {
        dState.containerHeight = action.payload;
      })

    case TYPES.SET_VIEW_CHAT_HISTORY:
      return produce(mState, (dState) => {
        dState.viewHistory = action.payload;
      })

    case TYPES.SET_SEARCH_WORD:
      return produce(mState, (dState) => {
        dState.searchWord = action.payload;
      })

    case TYPES.SET_SEARCH_Query:
      return produce(mState, (dState) => {
        dState.addHistory = action.payload;
      })
    case TYPES.SET_Selected_MessageId:
      return produce(mState, (dState) => {
        dState.scrollMessageId = action.payload;
      })

    case TYPES.SET_Scroll_Id:
      return produce(mState, (dState) => {
        dState.scrollId = action.payload;
      })

    case TYPES.AUDIO_SRC:
      return produce(mState, (dState) => {
        const messageIndex = dState.messages.findIndex(
          (message) => message.id === action.payload.id && message.type === 'assistant'
        );
        if (messageIndex !== -1) {
          dState.messages[messageIndex].audio_src_link = action.payload.audioSrcLink;
        }
        const newMessageIndex = dState.newMessages.findIndex(
          (newMessage) => newMessage.id === action.payload.id && newMessage.type === 'assistant'
        );
        if (newMessageIndex !== -1) {
          dState.newMessages[newMessageIndex].audio_src_link = action.payload.audioSrcLink
        }
      })

    case TYPES.INIT_USER_DETAILS:
      return produce(mState, (dState) => {
        dState.newMessages = [];
        dState.chats = [];
        dState.messages = [];
        dState.userPrompts = [];
        dState.communityPrompts = [];
      });

    case TYPES.CLEAR_NEW_MESSAGES:
      return produce(mState, (dState) => {
        dState.newMessages = [];
      });

    case TYPES.CLEAR_HISTORY_MESSAGES:
      return produce(mState, (dState) => {
        dState.messages = [];
      })

    case TYPES.REGENERATE_MODEL_NAME:
      return produce(mState, (dState) => {
        dState.regenerateModel = action.payload;
      });

    case TYPES.SET_CHAT_HISTORY_LOADING:
      return produce(mState, (dState) => {
        dState.chatLoading = action.payload;
      });

    case TYPES.SET_ADMIN_DELETED:
      return produce(mState, (dState) => {
        dState.AdminDelErrorMsg = action.payload;
      })

    case TYPES.CREDITS_LOADING_ANIMATION:
      return produce(mState, (dState) => {
        dState.creditLoad = action.payload;
      });

    case TYPES.SET_CURRENT_INDEX:
      return produce(mState, (dState) => {
        dState.currentMessageInd = action.payload.currentInd;
      })

    default:
      return { ...mState };
  }
};
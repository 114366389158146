import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Theme } from "theme";
import styled from "styled-components";
import * as yup from "yup";
import ReactGA from "react-ga4";

import { Field, FieldProps, Formik } from "formik";
import { AuthContainer } from "../components/AuthContainer";
import { FieldItem, Form } from "../components/FormComponents";
import { GoogleAuthBtn } from "../components/GoogleAuthBtn";
import { LabeledInput, Separator, LoadingButton } from "components";
import { RoutePaths } from "../../routePaths";
import { PageTitle } from "components/PageTitle/PageTitle";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";
import { login } from "redux/actions";
import MixpanelAnalytics from "utils/mixpanel";

const FormContainer = styled.div`
  max-width: 550px;
  width: 100%;
  padding: 0 28px 68px 28px;
        @media screen and (max-width: 576px) {
    padding: 0 44px 68px 38px;
}
`;

const Heading = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
         @media screen and (max-width: 576px) {
    font-size: 25px;
    line-height:normal;
}
           @media screen and (max-width: 350px) {
    font-size: 22px;
    line-height:normal;
}
  @media screen and (min-width: 769px) {
    font-size: 30px;
    line-height: 54px;
  }
`;

const TextContainer = styled.div
  `
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
 color:#666;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
          @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height:normal;
}
`;

const LinkText = styled(Link)`
  color: #527AE6;
`;

const LoginPageValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("login.email.error")
    .required("login.email.empty.error"),
});

export const LoginPage = () => {
  const themeMode = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  return (
    <AuthContainer>
      <PageTitle title="login.page.heading" />
      <FormContainer>
        <Heading themeMode={themeMode}>
          <FormattedMessage id="login.page.heading" />
        </Heading>
        <Separator height="33px" />
        <Formik
          initialValues={{ email: "" }}
          validationSchema={LoginPageValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            ReactGA.event({
              action: "Authentication_EmailLogin_Initiated",
              category: "WebsiteEmailLoginScreenLoginBtn_category",
              label: "WebsiteEmailLoginScreenLoginBtn_label",
              value: 1,
            });
            MixpanelAnalytics.trackLogin('email');

            login(values)
              .then(() => {
                localStorage.setItem('email', values.email);
                MixpanelAnalytics.identify(values.email, {
                  email: values.email
                });
                push(`/${lang}/${RoutePaths.EmailSent}`);
                setSubmitting(false);
              })
              .catch((err: any) => {
                triggerNotification({
                  message: err?.data?.message,
                  type: "error",
                });
                setSubmitting(false);
              });
          }}
          validateOnBlur
          validateOnChange
        >
          {({ isSubmitting }) => (
            <Form data-testid='login-form'>
              <FieldItem bottom="10">
                <Field name="email">
                  {({ field, meta }: FieldProps<string>) => (
                    <LabeledInput
                      {...field}
                      labelAdditionLength={0}
                      placeholder={formatMessage({
                        id: "login.email.placeholder",
                      })}
                      type="text"
                      error={!!meta.error && meta.touched}
                      message={
                        meta.touched &&
                        meta.error &&
                        formatMessage({ id: meta.error })
                      }
                      variant={themeMode}
                      data-testid='email-login'
                      inputSpace={true}
                      loginField={true}
                    />
                  )}
                </Field>
              </FieldItem>
              <LoadingButton
                full
                type="submit"
                isloading={isSubmitting}
                data-testid='login-btn'
                login={true}
              >
                <FormattedMessage id="login.btn.text" />
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <Separator height="25px" />
        <div className="flex items-center justify-center gap-4">
          {/* Left Divider Line */}
          <div className="h-[2px] flex-1" style={{ backgroundColor: "rgba(102, 102, 102, 0.25)" }}></div>

          {/* Center Text using TextContainer */}
          <TextContainer>
            <FormattedMessage id="login.form.seperator.text" />
          </TextContainer>

          {/* Right Divider Line */}
          <div className="h-[2px] flex-1" style={{ backgroundColor: "rgba(102, 102, 102, 0.25)" }}></div>
        </div>

        <Separator height="25px" />
        <GoogleAuthBtn themeMode={themeMode} dataTestID={'google-auth-login-btn'} type="login">
          <FormattedMessage id="login.socialAuth.google.btn.text" />
        </GoogleAuthBtn>
        <Separator height="25px" />
        <div className="h-[2px] flex-1" style={{ backgroundColor: "rgba(102, 102, 102, 0.25)" }}></div>
        <Separator height="25px" />
        <TextContainer >
          {/* <FormattedMessage id="login.form.seperator.text" />
          &nbsp;&nbsp; */}
          <LinkText data-testid='login-form-link' to={`/${lang}/${RoutePaths.Signup}`}>
            <FormattedMessage id="login.form.link" />
          </LinkText>
        </TextContainer>
      </FormContainer>
    </AuthContainer>
  );
};

export const SendIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <g clip-path="url(#clip0_11595_837)">
                <path d="M13.2718 0.143566C13.5308 0.323053 13.6667 0.633309 13.618 0.943566L11.9769 11.6102C11.9385 11.8589 11.7872 12.0769 11.5667 12.2C11.3462 12.3231 11.0821 12.3384 10.8487 12.241L7.78207 10.9666L6.02566 12.8666C5.79746 13.1154 5.43848 13.1974 5.1231 13.0743C4.80772 12.9513 4.60259 12.6461 4.60259 12.3077V10.1641C4.60259 10.0615 4.64105 9.96408 4.71028 9.88716L9.00772 5.19998C9.15643 5.03844 9.1513 4.78972 8.99746 4.63587C8.84361 4.48203 8.59489 4.47177 8.43336 4.61793L3.21797 9.25126L0.953869 8.11792C0.682074 7.98203 0.507715 7.71023 0.500023 7.40767C0.492331 7.1051 0.651305 6.82305 0.912843 6.67177L12.4 0.107668C12.6744 -0.048742 13.0128 -0.0333574 13.2718 0.143566Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_11595_837">
                    <rect width="13.1282" height="13.1282" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
};
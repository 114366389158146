import { useEffect } from "react";
import classNames from "classnames";
import styles from "./Credits.module.scss";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { Wallet } from "../../icons";

import { useSelector } from "redux/hooks";
import { Loader } from "components/Loader";

interface IProp {
  settingMiniBar?: boolean;
  mdNav?: boolean;
}

export const Credits = ({ settingMiniBar, mdNav }: IProp) => {
  const { userDetail, creditLimits, theme } = useSelector(
    (state) => state.authReducer
  );
  const { creditLoad } = useSelector(
    (state) => state.chatReducer
  );
  const { includeRoute } = useRouter();

  const getReaminingCredits = (): number => {
    const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
    if (userDetail?.user.team.role === "admin") {
      return adminCredits;
    } else {
      const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
      return dailyCredits > 0 ? dailyCredits : 0;
    }
  };
  const isChat = includeRoute(RoutePaths.Chat);
  const isSetting = includeRoute(RoutePaths.Settings);
  const isWorkspaces = includeRoute(RoutePaths.Workspaces);

  useEffect(() => {
    const newCredits = getReaminingCredits();
    window.postMessage({ credits: newCredits });
  }, [
    userDetail?.user.activeSubscription.credits,
    creditLimits.used_today,
    userDetail?.user.team.role,
    creditLimits.daily_limit,
  ]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.chat]: isChat || isWorkspaces,
        [styles.chatCredit]: theme === 'dark' && (isChat || isWorkspaces),
        [styles.miniSidebar]: settingMiniBar,
        [styles.isSetting]: isSetting,
      })}
    >
      <Wallet />
      {!settingMiniBar && (
        creditLoad ? (
          <Loader />
        ) : (
          <div className={classNames(styles.credits, {
            [styles.mdNav]: mdNav,
          })} id="deft-credit">
            {getReaminingCredits()}
          </div>
        )
      )}
    </div>
  );
};

export const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
            <g clip-path="url(#clip0_11568_329)">
                <path d="M11.5594 0.440674C10.9734 -0.145264 10.0219 -0.145264 9.43594 0.440674L3.43594 6.44067C2.85 7.02661 2.85 7.97817 3.43594 8.56411C4.02188 9.15005 4.97344 9.15005 5.55937 8.56411L9 5.12349V15C9 15.8297 9.67031 16.5 10.5 16.5C11.3297 16.5 12 15.8297 12 15V5.12349L15.4406 8.56411C16.0266 9.15005 16.9781 9.15005 17.5641 8.56411C18.15 7.97817 18.15 7.02661 17.5641 6.44067L11.5641 0.440674H11.5594ZM3 16.5C3 15.6704 2.32969 15 1.5 15C0.670312 15 0 15.6704 0 16.5V19.5C0 21.9844 2.01562 24 4.5 24H16.5C18.9844 24 21 21.9844 21 19.5V16.5C21 15.6704 20.3297 15 19.5 15C18.6703 15 18 15.6704 18 16.5V19.5C18 20.3297 17.3297 21 16.5 21H4.5C3.67031 21 3 20.3297 3 19.5V16.5Z" fill="#B3B3B3" />
            </g>
            <defs>
                <clipPath id="clip0_11568_329">
                    <rect width="21" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MixpanelAnalytics from "utils/mixpanel";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    MixpanelAnalytics.trackPageView(window.location.href);
  }, [location]);
};
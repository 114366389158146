import { InputHTMLAttributes, LegacyRef } from "react";
import { useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./SearchField.module.scss";

import { RemoveIcon } from "../icons";

import { useSelector } from "redux/hooks";
import { SearchIcon } from "../icons/SearchIcon";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: LegacyRef<HTMLInputElement>;
  onClear?: () => void;
  isPrompt?: boolean;
  chatsidebar?: boolean;
  prompt?: boolean;
  handleSerchFocus?: () => void;
  gptModal?: boolean;
  setIsPositionTop?: React.Dispatch<React.SetStateAction<boolean>>;
  isPositionTop?: boolean;
}

export const SearchField = ({
  value,
  inputRef,
  onClear,
  isPrompt,
  chatsidebar,
  prompt,
  handleSerchFocus,
  gptModal,
  setIsPositionTop,
  isPositionTop,
  ...props
}: IProps) => {
  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);
  const { PrivateChat} = useSelector(
    (state) => state.chatReducer
  );
  const { showCreateWorkspace } = useSelector((state) => state.workSpaceReducer);

const handleInputField = () => {

  if (setIsPositionTop) {
  setIsPositionTop!(true);
  }
}

const handleBlur = () => {   
  setTimeout(()=>{
    if (setIsPositionTop) {       
      setIsPositionTop(false);     
    }   
  } ,250)     

};
  
  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
        [styles.withFocus]: true && !chatsidebar && !gptModal,
        [styles.addPrompt] : isPrompt=== true,
        [styles.sidebarfield]: chatsidebar,
        [styles.gptSearchField]: theme==='dark' && gptModal,
        [styles.isbackdrop]: (PrivateChat || showCreateWorkspace) && chatsidebar,
      })}
      onClick={handleSerchFocus}
    >
      {!value && (
        <SearchIcon chatsidebar={chatsidebar}
        />
      )}
      <input
        {...props}
        ref={inputRef}
        value={value}
        className={classNames(styles.searchField, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
          [styles.sidebarfield]: chatsidebar,
          [styles.gptModal] : theme==='dark' && gptModal,
          [styles.searchPrompt] :isPrompt,
        })}
        onBlur={handleBlur} 
        onClick={handleInputField}
        placeholder={
          isPrompt
            ? formatMessage({
              id: "prompt.search",
            }) :
            gptModal
              ? formatMessage({
                id: "gptmodel.search.placeholder",
              })
              : formatMessage({
                id: "sidebar.chat.searchField.placeholder",
              })
        }
      />
      {value &&  (
        <button
          className={classNames(styles.removeBtn,
            { [styles.light]: prompt && theme === "light" || gptModal && theme==='light' }
          )}
          onClick={onClear}
          data-testid='search-clear-btn'
        >
          <RemoveIcon />
        </button>
      )}
    </div>
  );
};

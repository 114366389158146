import { FormattedMessage } from "react-intl";
import styles from "./configureAIBehaviour.module.scss";
import { DropDown } from "components";
import { Field, Formik, FieldProps } from "formik";
import { Form } from "pages/AuthPages/components/FormComponents";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import { useIntl } from "react-intl";
import { CloseIcon } from "components/icons/CloseIcon";
import { Notifications } from "../Notifications/Notifications";
import { useSelector } from "redux/hooks";
import { useState, useRef, useEffect } from "react";

interface IProp {
    EditIntructionModal?: boolean;
    onClose?: () => void;
    formRef?: React.RefObject<any>;
}

interface WorkspaceOption {
    id: string;
    actualValue: string;
}

export const ConfigureAIBehaviour = ({ EditIntructionModal, onClose, formRef }: IProp) => {
    const workspaceIds: WorkspaceOption[] = [
        { 
            id: "workspace.ai.research",
            actualValue: "research_analysis"
        },
        { 
            id: "workspace.ai.content",
            actualValue: "content_creation"
        },
        { 
            id: "workspace.ai.technical",
            actualValue: "technical_development"
        },
        { 
            id: "workspace.ai.design",
            actualValue: "design_work"
        },
        { 
            id: "workspace.ai.custom",
            actualValue: "custom"
        }
    ];

    const { formatMessage } = useIntl();
    const { theme } = useSelector((state) => state.authReducer);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const { inProgressWorkspace } = useSelector((state) => state.workSpaceReducer);
    const [selectedOption, setSelectedOption] = useState<string>(inProgressWorkspace?.instructions || "");
    const [customDescription, setCustomDescription] = useState<string>(inProgressWorkspace?.description || "");

    // Descriptions mapped to workspace options
    const descriptions: Record<string, string> = {
        "workspace.ai.research": formatMessage({ id: "workspace.ai.research.description" }),
        "workspace.ai.content": formatMessage({ id: "workspace.ai.content.description" }),
        "workspace.ai.technical": formatMessage({ id: "workspace.ai.technical.description" }),
        "workspace.ai.design": formatMessage({ id: "workspace.ai.design.description" }),
    };

 
    const dropdownOptions = workspaceIds.map((workspace) => ({
        label: (
            <div className="flex gap-2 items-center">
                <span>{formatMessage({ id: workspace.id })}</span>
                <span>{formatMessage({ id: `${workspace.id}.details` })}</span>
            </div>
        ),
        value: workspace.id,
        actualValue: workspace.actualValue
    }));


    useEffect(() => {
        if (inProgressWorkspace) {
            setSelectedOption(inProgressWorkspace.instructions || ""); 
        }
    }, [inProgressWorkspace]);
    
    // Update state when the dropdown value changes
    const handleDropdownChange = (option: { value: string }) => {
        setSelectedOption(option.value);
        if (option.value === "workspace.ai.custom") {
            setCustomDescription("");
        }
        // Reset scroll position when the description changes
        if (textareaRef.current) {
            textareaRef.current.scrollTop = 0;
        }
    };
    console.log("Selected Option:", selectedOption); 
    return (
        <div className={classNames(styles.container, {
            [styles.wrraperContainer]: !EditIntructionModal,
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
        })}>
            <div className={styles.headContainer}>
                {!EditIntructionModal ? (
                    <div className={classNames(styles.heading, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                    })}>
                        <FormattedMessage id="workspace.ai.head" />
                    </div>
                ) : (
                    <div className="flex w-full justify-between pb-[10px]">
                        <div className={classNames(styles.modalHeader, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}>
                            <FormattedMessage id="workspace.context.edit.instruction" />
                        </div>
                        <span
                            data-testid="prompt-close"
                            className="flex items-center justify-end hover:cursor-pointer"
                            onClick={onClose}
                        >
                            <CloseIcon workSpace={true} />
                        </span>
                    </div>
                )}

            </div>

            {/* Editable Textarea */}
            <Formik
                innerRef={formRef}
                onSubmit={(values) => {
                    console.log("Submitted Instruction: ", values.instruction);
                    console.log("Submitted Description: ", values.description);
                }}
                initialValues={{
                    description: inProgressWorkspace?.description || selectedOption === "workspace.ai.custom" ? customDescription : descriptions[selectedOption] || "",
                    instruction: inProgressWorkspace?.instructions || selectedOption
                }}
                enableReinitialize
            >
                {({ setFieldValue, values }) => (
                    <Form data-testid="add-prompt-form">

                        <div className="flex flex-col gap-[18px]">
                            {/* Dropdown */}
                            <DropDown
                                $background={theme === "light" ? "#fff" : "transparent"}
                                variant={theme}
                                height={33}
                                className={classNames(styles.select, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                })}
                                workspace={true}
                                value={values.instruction}
                                options={dropdownOptions}
                                placeholder={formatMessage({ id: 'workspace.ai.select.placeholder' })}
                                onChange={(option) => {
                                    const value = option.actualValue;
                                    setFieldValue("instruction", value); 
                                    handleDropdownChange(option); 
                                }}
                            />

                            <Field name="description">
                                {({ field, meta }: FieldProps<string>) => (
                                    <>
                                        {meta.touched && meta.error && (
                                            <div id="error-text" className="text-[#ff5454] pb-[5px] font-medium text-sm">
                                                {formatMessage({ id: meta.error })}
                                            </div>
                                        )}
                                        <TextareaAutosize
                                            ref={textareaRef}
                                            {...field}
                                            className={classNames(styles.textarea, {
                                                [styles.error]: meta.touched && meta.error,
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}
                                            minRows={6}
                                            maxRows={7.5}
                                            placeholder={formatMessage({ id: "workspace.ai.placeholder" })}
                                            value={selectedOption === "workspace.ai.custom" ? customDescription : values.description}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setFieldValue("description", value);
                                                if (selectedOption === "workspace.ai.custom") {
                                                    setCustomDescription(value);
                                                }
                                            }}
                                            data-testid="prompt-textarea"
                                        />
                                    </>
                                )}
                            </Field>
                        </div>
                    </Form>
                )}
            </Formik>

            {/* Notifications */}
            <Notifications
                notificationIds={[
                    "workspace.ai.notification1",
                    "workspace.ai.notification2",
                    "workspace.ai.notification3",
                    "workspace.ai.notification4",
                ]}
                headingId="workspace.ai.notificationhead"
            />
        </div>
    );
};
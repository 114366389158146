interface IProp {
  workSpace?: boolean;
}
export const WorkspaceIcon = ({ workSpace }: IProp) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 17 14"
      fill="none"
    >
      <g clip-path="url(#clip0_11380_174)">
        <path
          d="M6.4 1.6H9.6V3.2H6.4V1.6ZM6 0C5.3375 0 4.8 0.5375 4.8 1.2V3.6C4.8 4.2625 5.3375 4.8 6 4.8H7.2V5.6H0.8C0.3575 5.6 0 5.9575 0 6.4C0 6.8425 0.3575 7.2 0.8 7.2H3.2V8H2C1.3375 8 0.8 8.5375 0.8 9.2V11.6C0.8 12.2625 1.3375 12.8 2 12.8H6C6.6625 12.8 7.2 12.2625 7.2 11.6V9.2C7.2 8.5375 6.6625 8 6 8H4.8V7.2H11.2V8H10C9.3375 8 8.8 8.5375 8.8 9.2V11.6C8.8 12.2625 9.3375 12.8 10 12.8H14C14.6625 12.8 15.2 12.2625 15.2 11.6V9.2C15.2 8.5375 14.6625 8 14 8H12.8V7.2H15.2C15.6425 7.2 16 6.8425 16 6.4C16 5.9575 15.6425 5.6 15.2 5.6H8.8V4.8H10C10.6625 4.8 11.2 4.2625 11.2 3.6V1.2C11.2 0.5375 10.6625 0 10 0H6ZM2.4 11.2V9.6H5.6V11.2H2.4ZM10.4 9.6H13.6V11.2H10.4V9.6Z"
          fill={workSpace? " #090909" : "#fff"}
        />
      </g>
      <defs>
        <clipPath id="clip0_11380_174">
          <rect width="24" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { store } from "redux/store";
import { TYPES } from "redux/types";
import { ICRWorkSpace, IWorkspace, UploadFile, WorkspaceCR, WorkspaceUploadSrc, IUploadedFile, UpdateWs  } from "../chatInterface";
import { api } from "redux/api";
import { CancelToken } from "axios";


export const AddWorkSpace = (AddWorkSpace: IWorkspace) => {
  store.dispatch({ type: TYPES.ADD_WORKSPACE, payload: AddWorkSpace });
}

export const setShowCreateWorkspace = (createWorkSpace: boolean) => {
  store.dispatch({ type: TYPES.CREATE_WORKSPACE, payload: createWorkSpace });
};

export const addFile = (file: UploadFile) => {
  store.dispatch({ type: TYPES.ADD_FILE, payload: file });
};

export const updateFileStatus = (id: string, status: UploadFile["status"]) => {
  store.dispatch({ type: TYPES.UPDATE_FILE_STATUS, payload: { id, status } });
};

export const AddUploadedId = (id: string, uploaded_id: number) => {
  store.dispatch({ type: TYPES.UPLOADED_FILE_ID, payload: { id, uploaded_id } });
};

export const updateErrorMessage = (
  id: string,
  status: UploadFile["status"],
  errorMessage: string
) => {
  store.dispatch({
    type: TYPES.UPDATE_FILE_ERR_MESSAGE,
    payload: { id, status, errorMessage },
  });
};

export const updateFileS3Link = (
  id: string,
  status: UploadFile["status"],
  S3Link: string
) => {
  store.dispatch({
    type: TYPES.UPDATE_FILE_S3_LINK,
    payload: { id, status, S3Link },
  });
};

export const removeFile = (id: string) => {
  store.dispatch({ type: TYPES.REMOVE_FILE, payload: id });
};

export const updateFileProgress = (id: string, progress: number) => {
  store.dispatch({  type: TYPES.UPDATE_FILE_PROGRESS, payload: { id, progress }});
};

export const updateFileSize = (id: string, fileSize: number) => {
  store.dispatch({ type: TYPES.UPDATE_FILE_SIZE, payload: {id, fileSize}});
}

export const wsUpdateUPloadedFiles = (addFiles: IUploadedFile ) => {
  store.dispatch({type: TYPES.UPDATE_UPLOADED_FILE, payload: addFiles})
}

export const RemoveWSFiles = () => {
  store.dispatch({type: TYPES.REMOVE_WS_FILES, payload: []});
}

export const AddUploadedFile = (file: IUploadedFile) => {
  store.dispatch({type: TYPES.ADD_FILE_UPLOADED, payload: file });
}

export const delRemoveFile = (id: string) => {
  store.dispatch({type: TYPES.DEL_REMOVE_FILE, payload: id});
}

export const setEmptyFile = () => {
  store.dispatch({type: TYPES.EMPTY_FILE, payload: null})
}

export const setInProgressWS = (name: string, emoji: string, description: string, instructions: string) => {
  store.dispatch({type: TYPES.WS_PROGESSVALUES, payload : {name, emoji, description, instructions}});
}

export const setCurrentConversation = (id: number) => {
  store.dispatch({type: TYPES.CURRENT_CONVERSATION, payload : {id}});
}

// export const updateInProgressWS = (
//   updatedFields: { name?: string, emoji?: string, description?: string, instructions?: string }) => {
//   store.dispatch({type: TYPES.WS_PROGESSVALUES,payload: updatedFields, });
// };


export const WorkspaceCreation = (data: WorkspaceCR) => {
  return new Promise((resolve, reject) => {
      api
      .post("/api/workspace", data)
      .then((res) => {
        const response = res.data as ICRWorkSpace;
        AddWorkSpace(response.data)
        resolve(response.message);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const UploadSource = (data: WorkspaceUploadSrc, options?: { cancelToken?: CancelToken }) => {
  return new Promise((resolve, reject) => {
      api
      .post("/api/workspace-sources", data, { cancelToken: options?.cancelToken })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const ShowUploadSource = (workSpaceId: number) => {
  return new Promise((resolve, reject) => {
      api
      .get(`/api/workspace-sources?workspace_id=${workSpaceId}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_UPLOADED_FILES,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const UpdateSource = (id: number) => {
  return new Promise((resolve, reject) => {
      api
      .put(`/api/workspace-sources/${id}`)
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_UPLOADED_FILES,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const RefetchFile = (id: number) => {
  return new Promise((resolve, reject) => {
    api
    .post(`/api/workspace-sources/refetch/${id}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
});
}


export const DeleteUploadedFile = (id:number) => {
  return new Promise((resolve, reject) => {
  api
  .delete(`/api/workspace-sources/${id}`)
  .then((res) => {
    resolve(res.data);
    store.dispatch({
      type: TYPES.WS_UPLOADED_FILES_REMOVE,
      payload: {id}
    });
  })
  .catch((err) => {
    reject(err);
  });
});
}

export const ShowWorkspace = () => {
  return new Promise((resolve, reject) => {
      api
      .get("/api/workspace")
      .then((res) => {
        resolve(res.data);
        store.dispatch({
          type: TYPES.WS_CREATED,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const UpdateWorkspace = (id: number, data: UpdateWs) => {
  return new Promise((resolve, reject) => {
      api
      .put(`/api/workspace/${id}`, data)
      .then((res) => {
        resolve(res.data.message);
        store.dispatch({
          type: TYPES.WS_Updated,
          payload: res.data.data
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
import { Field, Formik, FieldProps } from "formik";
import styled from "styled-components";
import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import { LabeledInput } from "components";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "redux/hooks";
import EmojiPicker from 'emoji-picker-react';
import styles from "../CreateWorkspace/createworkspace.module.scss";
import { useEffect, useState, useRef } from "react";
import classNames from "classnames";

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
  
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 4px;
  margin-bottom: 0;
`;

export const WorkspaceName = ({
    formRef
}: {
    formRef: React.RefObject<any>;
}) => {
    const { formatMessage } = useIntl();
    const { theme } = useSelector((state) => state.authReducer);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    // const [selectedEmoji, setSelectedEmoji] = useState<string>("");
    const emojiPickerRef = useRef<HTMLDivElement>(null);
    const { inProgressWorkspace } = useSelector((state) => state.workSpaceReducer);
    // const handleEmojiSelect = (emoji: any) => {
    //     setSelectedEmoji(emoji.emoji);
    //     setShowEmojiPicker(false);
    // };

    const handleEmojiPickerClick = (e: React.MouseEvent) => {
        e.stopPropagation();  // Prevent event from bubbling up
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            const emojiPickerElement = emojiPickerRef.current;

            // Only close if click is outside the emoji picker container
            if (emojiPickerElement && !emojiPickerElement.contains(target)) {
                setShowEmojiPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={classNames(styles.fieldContainer, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
        })}>
            <h5 className={classNames(styles.question, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
            })}>
                <FormattedMessage id="workspace.create.question" />
            </h5>
            {/* <Formik
                innerRef={formRef}
                onSubmit={() => { }}
                initialValues={{ name: '', emoji: '' }}
                // validationSchema={validationSchema}
                validateOnBlur={false}

            >
                {({
                    errors, touched
                }) => (
                    <Form className={styles.fieldWrapper}>
                        <div ref={emojiPickerRef}
                            className={`${classNames(styles.emojiContainer, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                              })} ${errors.name && touched.name ? "mt-5" : ""}`} onClick={() => setShowEmojiPicker(!showEmojiPicker)} >
                            <div>
                                {selectedEmoji || '😀'}
                            </div>
                            {showEmojiPicker && (
                                <div
                                    style={{ position: "absolute", zIndex: 1000 }}
                                    onClick={handleEmojiPickerClick}
                                >
                                    <EmojiPicker onEmojiClick={handleEmojiSelect} />
                                </div>
                            )}
                        </div>

                        <FieldsRow>
                            <FieldRowItem>
                                <Field name="name">
                                    {({ field, meta }: FieldProps) => (
                                        <LabeledInput
                                            {...field}
                                            workspace={true}
                                            placeholder={formatMessage({ id: "workspace.create.placeholder" })}
                                            type="text"
                                            error={!!meta.error && meta.touched}
                                            message={meta.touched && meta.error && formatMessage({ id: meta.error })}
                                            variant={theme}
                                            // autoFocus
                                            data-testid='workspace-create-name'
                                        />
                                    )}
                                </Field>
                            </FieldRowItem>
                        </FieldsRow>
                    </Form>
                )}
            </Formik> */}
            <Formik
                innerRef={formRef}
                onSubmit={() => { }}
                initialValues={{
                    name: inProgressWorkspace?.name || "", 
                    emoji: inProgressWorkspace?.emoji || "😀", 
                  }}
                validateOnBlur={false}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form className={styles.fieldWrapper}>
                        <div
                            ref={emojiPickerRef}
                            className={`${classNames(styles.emojiContainer, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })} ${errors.name && touched.name ? "mt-5" : ""}`}
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        >
                            <div>{values.emoji}</div>
                            {showEmojiPicker && (
                                <div
                                    style={{ position: "absolute", zIndex: 1000 }}
                                    onClick={handleEmojiPickerClick}
                                >
                                    <EmojiPicker
                                        onEmojiClick={(emoji) => {
                                            setFieldValue("emoji", emoji.emoji); // Update Formik value
                                            setShowEmojiPicker(false);
                                        }}
                                    />
                                </div>
                            )}
                        </div>

                        <FieldsRow>
                            <FieldRowItem>
                                <Field name="name">
                                    {({ field, meta }: FieldProps) => (
                                        <LabeledInput
                                            {...field}
                                            workspace={true}
                                            placeholder={formatMessage({
                                                id: "workspace.create.placeholder",
                                            })}
                                            type="text"
                                            error={!!meta.error && meta.touched}
                                            message={meta.touched && meta.error && formatMessage({ id: meta.error })}
                                            variant={theme}
                                            data-testid="workspace-create-name"
                                        />
                                    )}
                                </Field>
                            </FieldRowItem>
                        </FieldsRow>
                    </Form>
                )}
            </Formik>

            <h5 className={styles.message}>
                <FormattedMessage id="workspace.create.message" />
            </h5>
        </div>
    );
};